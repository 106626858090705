import styled from 'styled-components'

export const AddOnsButton = styled.button`
	width: fit-content;
  	height: auto;
  	padding: 6px 26px;
  	border-radius: 8px;
  	background-color: #73333f;
	font-family: Libre Franklin;
  	font-size: 14px;
  	font-weight: bold;
  	font-stretch: normal;
  	font-style: normal;
  	line-height: 1.2;
  	letter-spacing: normal;
  	text-align: center;
	color: #fff;
	border: none;

	&:hover {
		background-color: #8E4250;
	}
`