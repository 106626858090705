/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { FieldValues, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { PageMainContainer } from './styles'
import AddUpdateForm from '../components/AddUpdateForm'
import { useCreatePaymentMethodMutation, useGetCustomerPaymentMethodQuery, useUpdatePaymentMethodMutation } from '../../../../../app/services'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import ErrorModal from '../../../../../components/error-modal'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import NoticeModal from '../modals/add-ons'

const PaymentMethod = (): ReactElement => {
	const { type } = useParams<{ type: string }>()
	const { userData } = useAppSelector(UsersFromStore)
	const { handleSubmit, setValue, setError, control } = useForm<FieldValues>()

	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)

	const notifInit = {
		message: '',
		show: false,
	}
	const [showNotification, setShowNotification] = useState<{ message: string, show: boolean }>(notifInit)
	const [isToggleNotice, setIsToggleNotice] = useState(false)

	const recaptchaRef = useRef(null)
	const customerId = userData?._id
	const isUpdate = type === 'edit-payment-method'
	const history = useHistory()
	const eligible = 'User is eligible for buying subscription'
	const notEligible = 'User is not eligible for buying subscription'

	const { data: paymentData } = useGetCustomerPaymentMethodQuery(customerId, { skip: !customerId })
	const [updatePayment, { isLoading: updateLoading }] = useUpdatePaymentMethodMutation()
	const [createPayment, { isLoading: createLoading }] = useCreatePaymentMethodMutation()

	useEffect(() => {
		if (!isUpdate && paymentData?.data?.length > 0) {
			setIsToggleNotice(true)
		}
	}, [isUpdate, paymentData])

	useEffect(() => {
		const nameParts = paymentData?.data?.length > 0 && paymentData?.data[0]?.cardholderName.split(' ')
		const firstName = paymentData && paymentData?.data?.length > 0 ? nameParts[0] : ''
		const lastName = paymentData && paymentData?.data?.length > 0 ? nameParts[nameParts.length - 1] : ''

		setValue('cardHolderFirstName', firstName || '')
		setValue('cardHolderLastName', lastName || '')
		setValue('expiryMonth', paymentData?.data?.length > 0 && paymentData?.data[0]?.expirationMonth)
		setValue('expiryYear', paymentData?.data?.length > 0 && paymentData?.data[0]?.expirationYear)
		setValue('zipCode', paymentData?.data?.length > 0 && paymentData?.data[0]?.billingAddress?.postalCode)
	}, [paymentData, setValue])

	const dateConfirmation = (billingExpirationData: string) => {
		const todate = moment(new Date())
		return todate.isBefore(moment(billingExpirationData, 'MM/YYYY')) || billingExpirationData === todate.format('MM/YYYY')
	}

	const handleRecaptchaTokenGenerated = (token: string | null) => {
		setRecaptchaToken(token)
	}

	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
			setRecaptchaToken(null)
		}
	}

	const onSubmit = async (data: FieldValues) => {
		const expirationDate = `${data.expiryMonth} ${data.expiryYear}`
		const paymentToken = paymentData?.data?.length > 0 && paymentData?.data[0]?.token
		const cardHolderName = data?.cardHolderFirstName && data?.cardHolderLastName ? `${data?.cardHolderFirstName} ${data?.cardHolderLastName}` : ''

		try {
			if (!isUpdate) { // ADD or CREATE
				const payload = {
					name: cardHolderName || '',
					number: data.cardNumber,
					cvv: data.cvv,
					expirationDate: data.expiryMonth + '/' + data.expiryYear,
					postalCode: data.zipCode,
					customerId: userData._id,
					'recaptcha_token': recaptchaToken
				}
				if (!recaptchaToken) {
					setShowNotification({
						message: 'Please verify the recaptcha.',
						show: true,
					})
					return
				}
				if (!dateConfirmation(expirationDate)) {
					setShowNotification({
						message: 'You cannot proceed with the payment because the credit card has expired.',
						show: true,
					})
					setError('expiryMonth', {
						type: 'custom',
						message: 'Selected expiry month is expired.'
					})
					setError('expiryYear', {
						type: 'custom',
						message: 'Selected expiry year is expired.'
					})
				} else {
					try {
						const csrf_token = await getCSURFToken()
						const createPaymentMethod: any = await createPayment({ params: payload, token: csrf_token }).unwrap()

						if (createPaymentMethod?.message === eligible) {
							history.push('/my-subscription/payment-method', {
								isPaymentRoute: true,
								action: 'add',
								isExpired: true,
								message: createPaymentMethod?.data?.planId,
							}) // Renew
						} else if (createPaymentMethod?.message === notEligible) {
							history.push('/my-subscription/payment-method', {
								isPaymentRoute: true,
								action: 'add',
								isExpired: false,
								message: '',
							}) // Re-route
						} else {
							history.push('/my-subscription/payment-method', {
								isPaymentRoute: true,
								action: 'error',
								isExpired: false,
								message: createPaymentMethod?.message || 'An unexpected error occurred. Please try again.',
							}) // Error
						}
					} catch (error) {
						console.error('error', error)
					}
				}
			} else { // UPDATE
				const payload = {
					name: cardHolderName || '',
					number: data?.cardNumber,
					cvv: data?.cvv,
					expirationDate: data?.expiryMonth + '/' + data?.expiryYear,
					postalCode: data?.zipCode,
					customerId: userData._id,
					token: paymentToken,
					'recaptcha_token': recaptchaToken
				}

				if (!recaptchaToken) {
					setShowNotification({
						message: 'Please verify the recaptcha.',
						show: true,
					})
					return
				}

				if (!dateConfirmation(expirationDate)) {
					setShowNotification({
						message: 'You cannot proceed with the payment because the credit card has expired.',
						show: true,
					})
					setError('expiryMonth', {
						type: 'custom',
						message: 'Selected expiry month is expired.'
					})
					setError('expiryYear', {
						type: 'custom',
						message: 'Selected expiry year is expired.'
					})
					handleRecaptchaReset()
				}

				const csrf_token = await getCSURFToken()

				const updatePaymentQuery: any = await updatePayment({ params: payload, token: csrf_token }).unwrap()

				if (updatePaymentQuery?.success) {
					if (updatePaymentQuery?.data?.cardDetails && updatePaymentQuery?.data?.planId) {
						history.push('/my-subscription/payment-method', { isPaymentRoute: true, action: 'update', isExpired: true, message: updatePaymentQuery?.data?.planId }) // renew
					} else {
						history.push('/my-subscription/subscription-details', { isPaymentRoute: true, action: 'update', isExpired: false, message: '' }) // re-route
					}
					handleRecaptchaReset()
				} else {
					if (updatePaymentQuery?.message) {
						if (updatePaymentQuery?.message == 'This user does not have any subscription') {
							history.push('/my-subscription/subscription-details', { isPaymentRoute: true, action: 'update', isExpired: false, message: '' }) // re-route
						} else {
							history.push('/my-subscription/payment-method', { isPaymentRoute: true, action: 'error', isExpired: false, message: updatePaymentQuery?.message }) // error
						}
					}
					handleRecaptchaReset()
				}
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const closeModal = () => {
		setShowNotification(notifInit)
	}

	const handleBackPaymentMethods = (action: string, isExpired: boolean) => {
		history.push('/my-subscription/payment-method', { isPaymentRoute: true, action: action, isExpired: isExpired })
	}

	return (
		<PageMainContainer isUpdate={isUpdate} isMobile={isMobile}>
			{isMobile ?
				<div className='header'>{isUpdate ? 'Update Card' : 'Add New Card'}</div>
				:
				<div className='header-container'>
					<div className='breadcrumb' onClick={() => handleBackPaymentMethods('', false)}>Manage Payment Methods</div>
					<div className='header'>{isUpdate ? 'Update Card' : 'Add New Card'}</div>
					<div className='breadcrumb'></div>
				</div>
			}
			<div className='payment-card'>
				<form onSubmit={handleSubmit(onSubmit)}>
					<AddUpdateForm control={control} title={'Card Details'} onRecaptchaTokenGenerated={handleRecaptchaTokenGenerated} recaptchaRef={recaptchaRef} />
					<div className='custom-button'>
						<div className='cancel-button' onClick={() => handleBackPaymentMethods('', false)}>CANCEL</div>
						<button type='submit' className='add-button'>
							{isUpdate ? 'UPDATE' : 'ADD'}
						</button>
					</div>
				</form>
			</div>
			{createLoading && <LoadingOverlay />}
			{updateLoading && <LoadingOverlay />}
			{showNotification.show &&
				<ErrorModal messageBody={showNotification.message} isOpen={showNotification.show} onClose={closeModal} />
			}

			{isToggleNotice &&
				<NoticeModal
					onClose={() => {
						//
					}}
					messageBody={'You currently have a payment method set up, and the system only allow one at a time. Adding another is not possible at this time.'}
					title={'PAYMENT METHODS'}
					isOpen={isToggleNotice}
					isPaymentMethod={true}
					isBlocked={true} />
			}
		</PageMainContainer>
	)
}

export default PaymentMethod
