import React, { CSSProperties } from 'react'
import { Button } from '../styles'

type Props = {
    type?: 'submit' | 'reset' | 'button' | undefined;
    name: string | undefined;
    onClick?: () => void;
    bgColor?: string;
    width?: string;
    textColor?: string; 
    height?: string;
    style?: CSSProperties | undefined
};

function CheckoutButton({type, name, bgColor, width, textColor, height, onClick, style}: Props): JSX.Element {

	const isSubmit = type === 'submit'

	return isSubmit ? <Button style={style} color={textColor} width={width} height={height} bgColor={bgColor} type={type}>{ name }</Button> : <Button style={style} color={textColor} width={width} height={height} bgColor={bgColor} onClick={onClick} type={type}>{ name }</Button>
}

export default CheckoutButton
