import React, { useEffect, useState, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Clases
import Article from '../../structure/classes/Article'

// i18n
import englishTranslation from './translations/en.json'
import { TranslationsUtil } from '../../utils'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

// Services
// import { useGetCommentsOfAnArticleQuery } from '../../app/services'
import { slugify, truncate } from '../../utils/ArticlesUtil'
import { PreviewContent } from './styles'
import useScreen from '../../helper/hooks/useScreen'
import { SetActiveFilters } from '../../app/slices/SearchFilters'
import { CloudImageUtils } from '../../utils/CloudImageUtils'
// import useArticleTracking from '../../helper/hooks/useArticleTracking'

function TaggedPreview(props: { article: Article, className?: string, extention?: boolean }): ReactElement {
	const { article, className, extention } = props
	const translate = translationUtil.getTranslator()

	// const { triggerTrackingHandler } = useArticleTracking()
	const screen = useScreen()
	const dispatch = useDispatch()

	const [authorName, setAuthorName] = useState('')
	const [authorId, setAuthorId] = useState('')
	// const [commentCount, setCommentCount] = useState(0)

	// const { data } = useGetCommentsOfAnArticleQuery(`article_id=${article.id}`, { skip: article.id ? false : true })

	// useEffect(() => {
	// 	if (article.author) {
	// 		setAuthorName(article.author.name)
	// 		setAuthorId(article.author.id)
	// 	}

	// }, [article, data])

	useEffect(() => {
		if (article.author) {
			setAuthorName(article.author.name)
			setAuthorId(article.author.id)
		}

	}, [article])

	const articledDisplay = () => {
		if(screen === 'tablet portrait'){
			return 60
		}

		return 45
	}

	return (
		<article className={`post post--vertical cat-1 ${className ?? 'post__height_22'}`}>
			<div className="post__thumb">
				<Link to={`/articles/${article.id}/${slugify(article.title)}`}>
					<CloudImageUtils imgSrc={article.image?.url} alt="image" ratio={0} params=''/>
				</Link>
			</div>
			<PreviewContent className={`post__text ${extention ? 'text-limit' : ''}`}>
				<h3 className="post__title typescale-2">
					<Link to={`/articles/${article.id}/${slugify(article.title)}`}>{truncate(article.title, articledDisplay())}</Link>
				</h3>
				<div className="post__excerpt">
					<Link to={`/articles/${article.id}/${slugify(article.title)}`}  className="post__excerpt link-normal">{article.excerpt}</Link>
				</div>
				<div className="post__meta post__meta_display" style={{ position: 'absolute', bottom: 0, width: '100%', backgroundColor:'white', paddingTop: '0.1rem' }}>
					<span className="entry-author text-gray-dark">{translate('By')}&nbsp;
						<Link to={`/author/${authorId}`} className="entry-author__name">{authorName}</Link>
					</span>
					<div className='post__meta_display' style={{ gap: 10 }}>
						<time className="time published text-gray-dark" dateTime={article.published_date} title={article.getPublishedDate('title')}><i className="mdicon mdicon-schedule"></i>{article.getPublishedDate('display')}</time>
						<Link style={{ color: '#333333'}}  onClick={()=> dispatch(SetActiveFilters({}))} to={`/search/wine?article_id=${article.id}`} ><img src='/img/icons/black.png' />&nbsp;{article.tasting_note_count}</Link>
					</div>
				</div>
			</PreviewContent>
		</article>
	)
}

export default TaggedPreview