/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'

// Page Content
import PaymentHistory from './PaymentHistory'
import PaymentMethods from './PaymentMethods'
import SubscriptionDetail from './SubscriptionDetail'
import SubscriptionHistory from './SubscriptionHistory'
import PaymentMethod from './payment-method'

import { IsUserAuthenticated } from '../../../../utils'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Notification, SubscriptionHeader } from '../subscriptions/styles'
import MenuSelections from '../../../../components/menu-selections/MenuSelections'
import { isMobile } from 'react-device-detect'

const menuOptions = [
	{ keyIndex: 0, label: 'Subscription Details', component: <SubscriptionDetail />, url: 'subscription-details', display: true },
	{ keyIndex: 1, label: 'Subscription History', component: <SubscriptionHistory />, url: 'subscription-history', display: true },
	{ keyIndex: 2, label: 'Payment History', component: <PaymentHistory />, url: 'payment-history', display: true },
	{ keyIndex: 3, label: 'Manage Payment Methods', component: <PaymentMethods />, url: 'payment-method', display: true },
	{ keyIndex: 4, label: 'Edit Payment Methods', component: <PaymentMethod />, url: 'edit-payment-method', display: false },
	{ keyIndex: 5, label: 'Add Payment Methods', component: <PaymentMethod />, url: 'add-payment-method', display: false },
]

interface LocationState {
	isPaymentRoute?: boolean
	action?: string
	isExpired: boolean
}

function MySubscription(): ReactElement {
	const location = useLocation<LocationState>()
	const { action } = location.state || {}
	const { type } = useParams<{ type: string }>()
	const history = useHistory()

	const [componentOption, setComponentOption] = useState<{
		label: string;
		component: JSX.Element;
		url: string;
		display: boolean;
		keyIndex: number;
	}>(menuOptions[0])

	useEffect(() => {
		if (!IsUserAuthenticated()) {
			history.push('/login')
		}
	}, [history])

	useEffect(() => {
		if(type){
			const component = menuOptions.find(option => option.url === type)
			if(component)
				setComponentOption(component)
		}
	}, [type])

	const menuOptionItems = () => {
		const menuFilters = menuOptions.filter(option => option.display)

		return menuFilters.map(menu => menu.label)
	}

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<SubscriptionHeader>My Subscription</SubscriptionHeader>
					<MenuSelections
						className="sub-selection"
						selectedMenu={componentOption.keyIndex}
						menuItems={menuOptionItems()}
						onMenuChange={(index: number) => history.push(`/my-subscription/${menuOptions[index].url}`)}
					/>
					{action == 'add' && (
						<Notification isMobile={isMobile}>
							<div className="message">Card has been added successfully</div>
						</Notification>
					)}
					{action == 'update' && (
						<Notification isMobile={isMobile}>
							<div className="message">Card Details have been updated successfully</div>
						</Notification>
					)}
					<article>{componentOption.component}</article>
				</div>
			</div>
		</div>
	)
}

export default MySubscription
