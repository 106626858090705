/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { UserName, ManageGroupContainers, PlanContainerButton, RemoveButton, RemoveSelectedButton, Separator, UserContainer, FormNotifications, BuyAddOnsButton, ResendInviteButton, ButtonActionContainer, SelectRemoveActionContainer, GroupContainer, GroupContentContainer, GroupHeaderContainer, UserListContainer, RemoveAllContainer } from '../styles'
import RemovalModal from './RemovalModal'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { useGetManagerDetailsByIdQuery } from '../../../../../app/services/Users'
import { useGetCompanyGroupsQuery, useGetSubscriptionDetailsQuery, useReInviteGroupUserMutation, useRemoveUserFromCompanyGroupMutation } from '../../../../../app/services'
import { dataCySelector } from '../../../../../app/services/constant'
import { SuccessNotification } from '../../my-profile/styles'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import SuccessModal from '../../../../../components/success-modal'
type SelectedGroupType = {
	id: string;
	state: boolean
}

type PaginationType = {
	totalPages: number;
	totalRecords: number;
	paging: number;
	allData: any[];
	paginatedData: any[];
}

const initialPagination = {
	totalPages: 0,
	totalRecords: 0,
	paging: 0,
	allData: [],
	paginatedData: []
}

const initResendState = {
	success: {
		isShow: false,
		message: ''
	}
}

const pagingOffset = 10

const pickedElements = (page: number, data: any[]) => {
	const startingIndex = (page - 1) * pagingOffset

	return data.slice(startingIndex, startingIndex + pagingOffset)
}

function ManageGroup(): ReactElement {

	const history = useHistory()
	const [selectAll, setSelectAll] = useState<SelectedGroupType[]>([])
	const [removedGroupsStatus, setRemovedGroupsStatus] = useState<boolean>(false)
	const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState(false)
	const [pagination, setPagination] = useState<PaginationType>(initialPagination)
	const [cancelState, setCancelState] = useState<any>({ selectedUserId: '', isSingle: false })

	const { userData } = useAppSelector(UsersFromStore)

	const [isManager, setIsManager] = useState(false)
	const [managerID, setManagerID] = useState('')

	const { data: subscriptionDetails, isLoading: subscriptionLoading } = useGetSubscriptionDetailsQuery(userData?.subscription?._id, { skip: !userData?.subscription?._id })
	const { data: managerDetails, isLoading: managerDetailsIsLoading } = useGetManagerDetailsByIdQuery(managerID, { skip: !managerID })

	const { data: fetchData, isError: getCompanyIsError } = useGetCompanyGroupsQuery(managerID, { skip: !managerID })
	const [isRemoveSuccess, setIsRemoveSuccess] = useState(false)
	const [seconds, setSeconds] = useState(3)
	const [totalActiveAccounts, setTotalActiveAccounts] = useState(0)

	const [executeReInviteGroupUserMutation, { data: reInviteUserData, isLoading: reInviteUserIsLoading }] = useReInviteGroupUserMutation()
	const [resendInviteState, setResendInviteState] = useState(initResendState)

	useEffect(() => {
		if (subscriptionDetails) {
			if (subscriptionDetails.success) {
				const userManager = subscriptionDetails?.data?.manager?.invite_manager_id ? subscriptionDetails?.data?.manager?.invite_manager_id === userData?._id : userData?.orbit_roles?.includes('project:customer-commercial-manager')

				if (userData?.orbit_roles?.includes('project:customer-commercial-manager')) {
					setManagerID(userData?._id)
				} else {
					setManagerID(userData?.subscription?.manager?.invite_manager_id ? userData?.subscription?.manager?.invite_manager_id : userData?.subscription?.manager?._id)
				}
				setIsManager(userManager)
			}
		}
	}, [subscriptionDetails])

	useEffect(() => {
		if (selectAll.length) {
			const filterAllChecked = selectAll.filter((group: any) => group.state)

			if (filterAllChecked.length) {
				return setRemovedGroupsStatus(true)
			}

			return setRemovedGroupsStatus(false)
		} else {
			setRemovedGroupsStatus(false)
		}
	}, [selectAll])

	useEffect(() => {
		if (fetchData) {
			if (fetchData.success) {
				if (fetchData.data.length) {
					setPagination({
						paging: pagingOffset,
						totalRecords: fetchData.data.length,
						totalPages: Math.ceil(fetchData.data.length / pagingOffset),
						allData: fetchData.data,
						paginatedData: pickedElements(1, fetchData.data)
					})

					let totalActiveAccounts = 0
					fetchData.data.forEach((data: any) => {
						if (data.profile && data.profile.account_status === 'active' && data.subscription.manager.invite_status !== 'Pending') {
							totalActiveAccounts++
						}
					})
					setTotalActiveAccounts(totalActiveAccounts)
				}
			}
		}

		if (getCompanyIsError) {
			console.log('Something went wrong. Please try again.')
		}
	}, [fetchData, getCompanyIsError])


	const [removeUserFromCompanyGroupMutation] = useRemoveUserFromCompanyGroupMutation()

	const removeUser = async (userId: string) => {
		const formData = {
			managerId: userData._id,
			userId: userId,
		}
		const removeData = await removeUserFromCompanyGroupMutation(formData)

		if ('data' in removeData && removeData.data?.success) {
			return removeData.data
		}
	}

	const handleRemoveUser = async () => {
		setIsLoading(true)
		setOpenRemoveModal(false)
		try {
			const success = [] as any, error = []
			let response
			if (cancelState.isSingle) {
				const res = await removeUser(cancelState.selectedUserId)
				if (res?.success) {
					setIsRemoveSuccess(true)
					response = res
					success.push(cancelState.selectedUserId)
				} else {
					error.push(cancelState.selectedUserId)
				}
			} else {
				response = await Promise.all(
					selectAll.map(async (data: any) => {
						const res = await removeUser(data.id)
						if (res?.success) {
							setIsRemoveSuccess(true)
							success.push(data.id)
						} else {
							error.push(data.id)
						}
						return res
					})
				)
				if (response) {
					setSelectAll([])
					setPagination((prevState: PaginationType) => ({
						...prevState,
						allData: prevState.allData.filter((item) => {
							return !success.includes(item._id)
						}),
						paginatedData: prevState.paginatedData.filter((item) => {
							return !success.includes(item._id)
						})
					}))
				}
			}
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (isRemoveSuccess && seconds > 0) {
			const timer = setTimeout(() => {
				setSeconds(prevSeconds => prevSeconds - 1)
			}, 1000)

			return () => clearTimeout(timer)
		} else if (seconds === 0) {
			window.location.reload()
		}
	}, [isRemoveSuccess, seconds])

	const selectHandler = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		const { value, checked } = event.target
		if (type === 'all') {
			if (checked) {
				const selectedAll = pagination.allData.map((group: any) => ({
					id: group._id,
					state: true
				}))
				setSelectAll(selectedAll)
			} else {
				setSelectAll([])
			}
		} else {
			let newList = [...selectAll]
			const updateWine = newList.find(group => group.id === value)
			if (updateWine) {
				updateWine.state = checked
			} else {
				newList = [...newList, {
					id: value,
					state: checked
				}]
			}
			setSelectAll(newList)
		}
		setCancelState({ selectedUserId: '', isSingle: false })
	}

	// const changePaginationHandler = (offset: number) => {
	// 	const pageNumber = offset + 1
	// 	setPagination((prevState) => ({
	// 		...prevState,
	// 		paginatedData: pickedElements(pageNumber, pagination.allData)
	// 	}))
	// 	window.scrollTo({ top: 200, behavior: 'smooth' })
	// }

	const handleCancelUser = (userId: string) => {
		setOpenRemoveModal(true)
		setCancelState({ selectedUserId: userId, isSingle: true })
	}


	const handleCloseRemoveModal = () => {
		setOpenRemoveModal(false)
		setCancelState({ selectedUserId: '', isSingle: false })
	}

	function convertedAddoOns(quantity: number, totalRecords: number) {
		if (isNaN(totalRecords - quantity) || quantity - totalRecords === -1) {
			return 0
		} else {
			return quantity - totalRecords
		}
	}

	const handleResendInvite = async (userEmail: string) => {
		try {
			const managerIDPayload = subscriptionDetails?.data?.manager?.invite_manager_id ? subscriptionDetails?.data?.manager?.invite_manager_id : subscriptionDetails?.data?.manager?._id ? subscriptionDetails?.data?.manager?._id : userData?._id
			const payload = {
				managerId: managerIDPayload,
				email: userEmail
			}

			const csrf_token = await getCSURFToken()
			await executeReInviteGroupUserMutation({ params: payload, token: csrf_token })

		} catch (err) {
			console.log(err)
		}
	}

	const closeModal = () => {
		setResendInviteState((state: any) => ({ ...state, success: { isShow: false, message: '' } }))
	}

	const isDisabledAdd = () => {
		// const planId = subscriptionDetails?.data?.planId
		// const addonQty = subscriptionDetails?.data?.addOns?.find((addOn: any) => addOn._id === 'additional-user' || addOn.inheritedFromId === 'additional-user')?.quantity

		// if (planId === 'commercial-1-year' && addonQty >= 2) {
		// 	return true
		// } else if (planId === 'commercial-1-year-t2' && addonQty >= 4) {
		// 	return true
		// } else if (planId === 'commercial-1-year-t3' && addonQty >= 40) {
		// 	return true
		// }

		return false
	}

	useEffect(() => {
		if (reInviteUserData) {
			if (reInviteUserData.success) {
				setResendInviteState((state: any) => ({ ...state, success: { isShow: true, message: 'Email sent successfully.' } }))
			} else {
				setResendInviteState((state: any) => ({ ...state, success: { isShow: true, message: JSON.stringify(reInviteUserData.message).replace(/</g, '\\u003c') } }))
			}
		}
	}, [reInviteUserData])

	const inviteManagerId = subscriptionDetails?.data?.manager?.invite_manager_id
	const userId = userData?._id
	const hasAdditionalUser = subscriptionDetails?.data?.addOns?.some(
		(addOn: any) => addOn._id === 'additional-user' || addOn.inheritedFromId === 'additional-user'
	)
	const buttonText = hasAdditionalUser ? 'BUY MORE SEATS' : 'BUY ADD-ONS'

	const handleClick = () => {
		if (!isDisabledAdd()) {
			history.push(`/buy-addons/${inviteManagerId ? inviteManagerId : userId}&type=add`)
		}
	}

	return (
		<>
			{reInviteUserIsLoading && <LoadingOverlay />}
			{subscriptionLoading && <LoadingOverlay />}
			{managerDetailsIsLoading && <LoadingOverlay />}
			{isLoading && <LoadingOverlay />}
			<GroupContentContainer isManager={managerDetails ? isManager : false}>
				<div className="mnmd-main-col content-container" role="main" >
					<ManageGroupContainers size={'24px'} >
						<div className='parent-header'>Group Information</div>
					</ManageGroupContainers>

					<ManageGroupContainers size={'16px'} >
						<div className='header'>Group ID: &nbsp;</div>
						<div className='value'>{managerDetails?.data?.subscription._id}</div>
					</ManageGroupContainers>

					<ManageGroupContainers size={'16px'} >
						<div className='header'>Group Manager: &nbsp;</div>
						<div className='value'>{managerDetails?.data?.profile?.name?.first_name} {managerDetails?.data?.profile?.name?.last_name}</div>
					</ManageGroupContainers>

					{managerDetails && isManager &&
						<>
							<ManageGroupContainers size={'16px'}>
								<div className='header'>Available addons: &nbsp;</div>
								<div className='value'>
									{convertedAddoOns(
										subscriptionDetails?.data?.addOns?.find((addOn: any) => addOn._id === 'additional-user' || addOn.inheritedFromId === 'additional-user')?.quantity || 0,
										totalActiveAccounts
									)} of {subscriptionDetails?.data?.addOns?.find((addOn: any) => addOn._id === 'additional-user' || addOn.inheritedFromId === 'additional-user')?.quantity || 0}
								</div>
							</ManageGroupContainers>
						</>
					}
					{isManager &&
						<>
							{(subscriptionDetails?.data?.addOns.length === 0 || subscriptionDetails?.data?.addOns[0]?.quantity === 0) &&
								<div>You currently have 0 available add-ons. If you would like to add more group users, please purchase more add-ons.</div>
							}
						</>
					}
					{(subscriptionDetails?.data?.addOns.length !== 0 || subscriptionDetails?.data?.addOns[0]?.quantity !== 0) &&
						<ManageGroupContainers size={'14px'}>
							{managerDetails && isManager &&
								<div className='manage-group-buttons'>
									<BuyAddOnsButton
										isDisabled={isDisabledAdd()}
										onClick={handleClick}
										{...dataCySelector('buy-addons-btn')}
									>
										<span>{buttonText}</span>
									</BuyAddOnsButton>
									<PlanContainerButton
										onClick={() => history.push('/my-company/add-group')}
										disabled={
											(() => {
												const additionalUserAddOn = subscriptionDetails?.data?.addOns?.find(
													(addOn: any) => addOn._id === 'additional-user'
												)
												return convertedAddoOns(additionalUserAddOn?.quantity || 0, totalActiveAccounts) === 0
											})()
										}
										{...dataCySelector('add-groupuser-btn')}
									>
										ADD GROUP USER
									</PlanContainerButton>

								</div>
							}
						</ManageGroupContainers>}
				</div>
			</GroupContentContainer>

			<UserListContainer>
				{/* {dummyPaginatedData.length > 0 && */}
				{pagination.paginatedData.length > 0 &&
					<GroupHeaderContainer>
						<Separator />
						<GroupContainer>
							<div className='header'>Group Users</div>
						</GroupContainer>
					</GroupHeaderContainer>
				}

				<div className='user-list'>
					{isRemoveSuccess &&
						<ManageGroupContainers size={'20px'} >
							<FormNotifications>
								<div className={'form-success deleted-success'}>
									<SuccessNotification>Successfully removed. The page will automatically refresh in {seconds} seconds.</SuccessNotification>
								</div>
							</FormNotifications>
						</ManageGroupContainers>
					}
					{/* <AlgoliaPagination totalPages={pagination.totalPages} totalRecords={pagination.totalRecords} paging={pagination.paging} render={changePaginationHandler} wineDisplay='default' /> */}

					{managerDetails && isManager && pagination.allData.length ?
						<RemoveAllContainer>
							<SelectRemoveActionContainer>
								<div className='selectCheckAll'>
									<input id='select-all' onChange={(e) => selectHandler(e, 'all')} type='checkbox' value='all' {...dataCySelector('select-all-btn')} />
									<label style={{ fontWeight: 'normal', cursor: 'pointer' }} htmlFor='select-all'>Select all</label>
								</div>
								<div>
									<RemoveSelectedButton onClick={() => setOpenRemoveModal(true)} disabled={!removedGroupsStatus} {...dataCySelector('remove-user-btn')}>REMOVE SELECTED</RemoveSelectedButton>
								</div>
							</SelectRemoveActionContainer>
						</RemoveAllContainer> : <></>}

					{pagination.paginatedData.map((data, idx) => {
						let selectedGroup = selectAll.find((selected) => selected.id === data._id)
						if (!selectedGroup) {
							selectedGroup = {
								id: data._id,
								state: false
							}
						}
						let status
						let isDisableUser = false
						const startDate = data?.subscription?.start
						const endDate = data?.subscription?.end
						const userAddress = data?.emails[0]?.address

						if (data?.subscription?.manager?.invite_status === 'Pending' || data?.subscription?.manager?.invite_status === 'Active') {
							status = data?.subscription?.manager?.invite_status === 'Pending' ? 'Pending' : 'Active'
							if (data?.profile?.account_status === 'inactive') {
								status = 'Inactive'
								isDisableUser = true
							}
						}

						return <div key={idx} style={{ width: '100%' }}>
							<UserContainer >
								<div className='name-status'>
									<UserName>
										{managerDetails && isManager &&
											<input onChange={(e) => selectHandler(e, data._id)} checked={selectedGroup.state} value={data._id} type='checkbox' {...dataCySelector(`select-${idx}-checkbox`)} />}
										{data?.profile?.name?.first_name ?
											<span className={isDisableUser ? 'isDisabled' : ''} >{data?.profile?.name.first_name} {data?.profile?.name?.last_name}</span>
											:
											<span className={isDisableUser ? 'isDisabled' : ''} >{data.username}</span>
										}
									</UserName>
									{managerDetails && isManager && <div className={isDisableUser ? 'isDisabled status' : 'status'} >{status}</div>}
								</div>

								{
									data.username && managerDetails && isManager
										? <p className={isDisableUser ? 'isDisabled username' : 'username'}>{data?.emails[0]?.address}</p>
										: data?.emails[0]?.address && <span className='username'>{data?.emails[0]?.address}</span>
								}

								{
									data.username && managerDetails && isManager
										? <div style={{ display: 'flex' }}>
											<p className={isDisableUser ? 'isDisabled username' : 'username'}>{moment(startDate).format('L')}</p>
											-
											<p className={isDisableUser ? 'isDisabled username' : 'username'}>{moment(endDate).format('L')}</p></div>
										: data?.emails[0]?.address && <span className='username'>{userAddress}</span>
								}

								<ButtonActionContainer>
									{managerDetails && isManager && <RemoveButton onClick={() => handleCancelUser(data._id)} {...dataCySelector(`cancel-user-${idx}`)}>DELETE USER</RemoveButton>}
									{managerDetails && isManager && status === 'Pending' && <ResendInviteButton onClick={() => handleResendInvite(data?.emails[0]?.address)} {...dataCySelector(`resend-invite-${idx}`)}>RESEND INVITE</ResendInviteButton>}
								</ButtonActionContainer>
							</UserContainer>
						</div>
					})}
				</div>
			</UserListContainer>

			<RemovalModal onProceed={handleRemoveUser} title='REMOVE USERS' isOpen={openRemoveModal} onClose={handleCloseRemoveModal}>
				<p>These users will no longer have access to subscription-only content. Are you sure you want to remove them?</p>
			</RemovalModal>

			{resendInviteState.success.isShow &&
				<SuccessModal messageBody={resendInviteState.success.message} isOpen={resendInviteState.success.isShow} onClose={closeModal} />
			}
		</>
	)
}

export default ManageGroup