/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { CompanyUpdateContainer, FormProfilePicContainer, NotificationPrompt } from './styles'
import Input from '../../../../components/form-components/input/Input'
import { FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import MultiSelect from '../../../../components/form-components/dropdown/MultiSelectDropdown'
import CompanyLogo from './CompanyLogo'
import Dropdown from '../../../../components/form-components/dropdown/Dropdown'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import { Authentication as AuthFromStore } from '../../../../app/slices/Authentication'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { useEditUserCompanyDetailsMutation, useGetCompanyDetailsQuery, useGetTradeDirectoryLookupCountriesQuery } from '../../../../app/services'
import businessTypeOptions from './helper/businessTypes'
import updateFormValues from './helper/updateFormValues'
import { reverseObfuscateConstant } from '../../../../utils/Constant'
import { RP_ENV } from '../../../../configs'
import LoadingOverlay from '../../../../components/Loading-overlay'
import ErrorModal from '../../../../components/error-modal'

type CountryType = {
	name: string
	code: string
	_id: string
}

const CompanyUpdate = (): ReactElement => {
	const { type } = useParams<{ type: string }>()
	const { userData } = useAppSelector(UsersFromStore)
	const authentication = useAppSelector(AuthFromStore)
	const [editUserCompanyDetailsMutation] = useEditUserCompanyDetailsMutation()
	const history = useHistory()

	const { data: CompanyDetails, isLoading: CompanyDetailsLoading } = useGetCompanyDetailsQuery(userData?._id, { skip: !userData })
	const { data: tradeDirectoryCountries, isError: tradeDirectoryCountriesIsError } = useGetTradeDirectoryLookupCountriesQuery('')

	const [listCountries, setListCountries] = useState<CountryType[]>([])
	const [lookupCountries, setLookupCountries] = useState<any>()
	const [errorState, setErrorState] = useState<any>()
	const [isListed, setIsListed] = useState<boolean>()
	const [initialData, setInitialData] = useState<any>()
	const [logoImage, setLogoImage] = useState<any>()
	const [newLogo, setNewLogo] = useState<any>()
	const [isLogoChange, setIsLogoChange] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [notificationSucess, setNotificationSuccess] = useState(false)
	const [counter, setCounter] = useState(5)
	const [hasVatNumber, setHasVatNumber] = useState<boolean>()

	const { control, handleSubmit, setValue } = useForm()

	useEffect(() => {
		if (tradeDirectoryCountries) {
			if (tradeDirectoryCountries.success) {
				const countryList = tradeDirectoryCountries?.data?.map((country: { _id: string; code: string; name: string; }) => country.name)
				setLookupCountries(countryList)
				setListCountries(tradeDirectoryCountries?.data)
			} else {
				setErrorState((state: any) => ({ ...state, isShow: true, message: 'Something went wrong!' }))
			}
		}
		if (tradeDirectoryCountriesIsError) {
			setErrorState((state: any) => ({ ...state, isShow: true, message: 'Something went wrong!' }))
		}
	}, [tradeDirectoryCountries, tradeDirectoryCountriesIsError])

	useEffect(() => {
		if (CompanyDetails && CompanyDetails.success) {
			const data = CompanyDetails.data
			setInitialData(data)
		}
	}, [CompanyDetails])

	const selectedCountry = useWatch({ name: 'country', control })

	useEffect(() => {
		if (selectedCountry) {
			const hasVatCountry: any = listCountries.find(country => country.name === selectedCountry)
			setHasVatNumber(hasVatCountry && hasVatCountry?.tax_value !== null ? true : false)
		}
	}, [selectedCountry, listCountries])

	useEffect(() => {
		if (notificationSucess) {
			setCounter(5)
			const countdownTimer = setInterval(() => {
				setCounter(prev => {
					if (prev <= 1) {
						clearInterval(countdownTimer)
						setNotificationSuccess(false)
						return 0
					}
					return prev - 1
				})
			}, 1000)
			return () => clearInterval(countdownTimer)
		}
	}, [notificationSucess])

	useEffect(() => {
		if (initialData) {
			setInitialData(initialData)
			updateFormValues(setValue, initialData)
			const logoUrl = initialData?.[0]?.user_owned_companies?.[0]?.logo?.url || ''
			setLogoImage(logoUrl)

			const trade_is_list = initialData?.[0].user_owned_companies[0]?.is_listed
			const isListed = trade_is_list === false ? false : true
			setIsListed(isListed)
		}
	}, [initialData, setValue])

	useEffect(() => {
		if (userData && !userData?.orbit_roles.includes('project:customer-commercial-manager')) {
			history.push('/')
		}
	}, [userData, history])

	const onSubmit: SubmitHandler<FieldValues> = async (data) => {
		setIsLoading(true)

		const countryData = tradeDirectoryCountries?.data?.find(
			(country: CountryType) => country.name === data.country
		)
		const typeValues = data?.type?.map((t: { value: string }) => t.value)

		const initialCompanyData = initialData[0]?.user_owned_companies[0] || {}

		const payload = {
			companyId: (data?._id ?? initialCompanyData._id) || '',
			name: (data?.name ?? initialCompanyData.name) || '',
			type: (typeValues ?? initialCompanyData.type) || '',
			street: (data?.street ?? initialCompanyData.address?.street) || '',
			street_optional: (data?.street_optional ?? initialCompanyData.address?.street_optional) || '',
			state: (data?.state ?? initialCompanyData.address?.state) || '',
			city: (data?.city ?? initialCompanyData.address?.city) || '',
			zip: (data?.zip ?? initialCompanyData.address?.zip) || '',
			country: (countryData ?? initialCompanyData.address?.country?.name) || '',
			contact_person: (data?.contact_person ?? initialCompanyData.contact_person) || '',
			email: (data?.email ?? initialCompanyData.email) || '',
			website: (data?.website ?? initialCompanyData.website_url) || '',
			phone: (data?.phone ?? initialCompanyData.phone) || '',
			about: (data?.about ?? initialCompanyData.about) || '',
			is_listed: isListed ?? initialCompanyData.is_listed,
			vatId: hasVatNumber ? data?.vatId : ''
		}

		const imageData = new FormData()
		imageData.append('image', newLogo)

		try {
			let logo = {
				_id: '',
				url: ''
			}
			if (isLogoChange) {
				const imageResponse = await uploadImage(imageData)
				if (imageResponse && imageResponse?.data.success) {
					logo = {
						...imageResponse.data.data
					}
				}
			}
			await updateCompanyDetails(payload, logo)
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	const updateCompanyDetails = async (formData: any, imageId: { _id: string; url: string }) => {
		try {
			if (imageId._id) {
				formData = {
					...formData,
					logo: imageId
				}
			}

			const editCompanyDetails = await editUserCompanyDetailsMutation({ params: formData, userID: userData._id }).unwrap()
			if (editCompanyDetails?.success) {
				scrollToTop()
				setNotificationSuccess(true)
				setTimeout(() => {
					history.push('/my-company/company-details')
				}, 5000)
			} else {
				setErrorState((state: any) => ({ ...state, isShow: true, message: editCompanyDetails.message || 'Error: Changes not saved. Please try again.' }))
			}
		} catch (error) {
			console.log(error)
		}
	}

	const uploadImage = async (imageData: any) => {
		try {
			const urlLogoUpload = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user-company/upload?customerId=${userData._id}`
			const response = await fetch(urlLogoUpload, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + authentication.User.accessToken,
					'x-api-key': RP_ENV.API_KEY,
				},
				body: imageData
			})

			const uploadedLogo = await response.json()

			if (uploadedLogo) {
				if (!uploadedLogo?.success) {
					return {
						message: uploadedLogo?.message
					}
				}

				return {
					data: uploadedLogo
				}
			}

		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	const getNewCompanyLogo = async (file: File): Promise<string> => {
		try {
			const reader = new FileReader()
			return new Promise(() => {
				reader.onload = () => {
					setNewLogo(file)
				}
				reader.readAsDataURL(file)
			})
		} catch (error) {
			console.error(error)
			return ''
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	const handleImageChange = (newImage: string) => {
		setLogoImage(newImage)
	}

	const closeModalError = () => {
		setErrorState((state: any) => ({ ...state, isShow: false, message: '' }))
	}

	const handleGoBack = () => {
		history.push('/my-company/company-details')
	}

	return (
		<CompanyUpdateContainer isCompany={type === 'update-company-information'}>
			{(isLoading || CompanyDetailsLoading) && <LoadingOverlay />}
			{type === 'update-company-information' ?
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='breadcrumbs' onClick={() => handleGoBack()}>My Company</div>
					<div className='header'>Company Information</div>
					{notificationSucess &&
						<NotificationPrompt>
							<div className='success'>Changes Saved. The page will automatically refresh in {counter} seconds.</div>
						</NotificationPrompt>
					}
					<div className='company-info-container'>
						<FormProfilePicContainer isCompany={true}>
							<CompanyLogo image={logoImage} onImageChange={handleImageChange} logoView={logoImage} getNewCompanyLogo={getNewCompanyLogo} setIsLogoChanged={setIsLogoChange} />
						</FormProfilePicContainer>
						<div className='payment-receipt-info'>This information will be displayed in your payment receipt.</div>
						<Input
							control={control}
							name='name'
							type='text'
							placeholder='Company Name'
						/>
						<MultiSelect
							control={control}
							name='type'
							placeholder='Business Type'
							options={businessTypeOptions || []}
						/>
						<div className='field-header'>Business Address</div>
						<Input
							control={control}
							name='street'
							type='text'
							placeholder='Street Address'
						/>
						<Input
							control={control}
							name='street_optional'
							type='text'
							placeholder='Extended Address (optional)'
						/>
						<div className='two-column'>
							<Input
								control={control}
								name='city'
								type='text'
								placeholder='City'
							/>
							<Input
								control={control}
								name='state'
								type='text'
								placeholder='State/Province (optional)'
							/>
						</div>
						<div className='two-column'>
							<Input
								control={control}
								name='zip'
								type='text'
								placeholder='Zip/Postal Code'
							/>
							<Dropdown
								disabled={false}
								control={control}
								options={lookupCountries || []}
								placeholder='Country'
								name='country'
							/>
						</div>

						{hasVatNumber &&
							<>
								<div className='field-header' style={{ margin: '8px 0 32px 0' }}>VAT ID</div>
								<div style={{ margin: '-10px 0 20px 0' }}>Businesses in member states of the European Union are required to provide the business’ registered VAT ID number.</div>
								<Input
									control={control}
									name='vatId'
									type='text'
									placeholder='*VAT ID'
									rules={{ required: 'VAT ID is required' }}
								/>
								<div style={{ marginBottom: '30px' }}></div>
							</>
						}

						<div className='custom-button'>
							<div className='cancel-button' onClick={() => handleGoBack()}>CANCEL</div>
							<button className='update-button' type='submit'>UPDATE</button>
						</div>
					</div>
				</form>
				:
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='breadcrumbs' onClick={() => handleGoBack()}>My Company</div>
					<div className='header'>Contact Information</div>
					{notificationSucess &&
						<NotificationPrompt>
							<div className='success'>Changes Saved. The page will automatically refresh in {counter} seconds.</div>
						</NotificationPrompt>
					}
					<div className='company-info-container'>
						<div className='sub-header'>Contact Information</div>
						<Input
							control={control}
							name='contact_person'
							type='text'
							placeholder='Contact Person Name'
						/>
						<Input
							control={control}
							name='email'
							type='text'
							placeholder='Business Email'
						/>
						<Input
							control={control}
							name='website'
							type='text'
							placeholder='website'
						/>
						<Input
							control={control}
							name='phone'
							type='text'
							placeholder='Phone'
						/>
						<Input
							control={control}
							name='about'
							type='textarea'
							placeholder='About the company'
						/>
						<div className='custom-button'>
							<div className='cancel-button' onClick={() => handleGoBack()} >CANCEL</div>
							<button className='update-button' type='submit'>UPDATE</button>
						</div>
					</div>
				</form>
			}
			{errorState?.isShow &&
				<ErrorModal messageBody={errorState?.message} isOpen={errorState?.isShow} onClose={closeModalError} />
			}
		</CompanyUpdateContainer >
	)
}

export default CompanyUpdate
