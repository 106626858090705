import styled from 'styled-components'
import { RPColors } from '../../styles/CSSConstant'

export const DropdownContainer = styled.div<{isMobile?: boolean, isError?: boolean}>`
    position: relative;
    width: 100%;

    & select {
        padding: 1.2rem 0.5rem;
        border-radius: 8px;
        border: ${({ isError }) => (isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)};

        ::placeholder {
            color: black;
        }
    }

    & .multi-select {
        border: 1px solid ${RPColors.borderColor};
        border-radius: 8px;
    }

    & .css-13cymwt-control, .css-t3ipsp-control {
        border: transparent;
        padding: 0.5rem 0;
        border-radius: 8px;

        & .css-1dyz3mf {
            gap: 0.5rem;
        }
    }


`

export const CheckboxContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 0.7rem !important;
    justify-content: center;

    & span {
        width: ${({ isMobile }) => isMobile ? '100%' : '35.5rem'};
        color: black;
        font-size: 14px;
    }
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

export const StyledCheckbox = styled.div<{ checked: boolean }>`
    display: inline-block;
    width: 30px;
    height: 30px;
    background: ${(props) => (props.checked ? '#73333F' : '#fff')};
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: all 150ms;
    cursor: pointer;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    }
`

export const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`

export const Button = styled.button<{ width?: string; height?:string; bgColor?: string; color?: string}>`
    background-color: ${({ bgColor }) => bgColor ? bgColor : '#73333f'};
    color: ${({ color }) => color ? color : 'white'};;
    font-weight: 500;
    border: 1px solid #73333f;
    border-radius: 4px !important;
    width: ${({ width }) => width ? width : '100%'};
    height: ${({ height }) => height ? height : '3.5rem'};
`

export const ErrorMessageComponent = styled.p`
    color: red;
    font-size: 12px;
`

export const InputContainer = styled.div`
    width: 100%;

    & .password-input {
        width: 100%;
        position: relative;

        & img {
            z-index: 1;
            position: absolute;
            cursor: pointer;
            width: 30px;
            height: 30px;
            right: 10px;
            bottom: 40px;
        }
    }
`

export const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
`

export const StyledInput = styled.input<{ isError?: boolean }>`
    width: 100%;
    height: 100%;   
    padding: 18px 10px 18px 10px !important;
    border-radius: 8px !important;
    border:  ${(props) => (props.isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)} !important;
    margin-bottom: 5px;

    ::placeholder {
        color: black;
    }

    &:focus, :hover {
        border:  ${(props) => (props.isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)} !important;
		transition: border 0.3s ease-in-out !important;
	}
`

export const StyledTextArea = styled.textarea<{ isError: boolean }>`
    width: 100%;
    height: 100%;   
    padding: 18px 10px 18px 10px !important;
    border-radius: 8px !important;
    border:  ${(props) => (props.isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)} !important;
    margin-bottom: 5px;

    ::placeholder {
        color: black;
    }

    &:focus, :hover {
        border:  ${(props) => (props.isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)} !important;
		transition: border 0.3s ease-in-out !important;
	}
`

export const ErrorMessage = styled.p`
    color: ${RPColors.borderColorError};
    font-size: 14px;
    margin-left: 5px;
    display: block;
    padding-bottom: 0;
    margin-bottom: 0;
`

export const CheckboxMainContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;

    & p {
        margin-left: 2.9rem;
    }
`

export const StyledDatePickerWrapper = styled.div<{ isError: boolean }>`
    width: 100%;
    position: relative;

    & svg {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
    }

    & .calendar-icon {
        cursor: pointer;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container input {
        width: 100%;
        padding: 1rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        border:  ${(props) => (props.isError ? `solid 2px ${RPColors.borderColorError}` : `solid 1px ${RPColors.borderColor}`)};
        border-radius: 8px;
    }

    .react-datepicker__header {
        background-color: #f0f0f0;
        border-bottom: 1px solid #ccc;
    }

    .react-datepicker__day--selected {
        background-color: #73333f;
        color: #fff;
    }

    .react-datepicker__day:hover {
        background-color: #8E4250;
    }
    
    
`

export const DatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`