import styled from 'styled-components'

export const ModalWrapper = styled.div<{ isOpen: boolean; isMobile: boolean }>`
	display: ${props => (props.isOpen ? 'flex' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	align-items: center;
	overflow-y: auto;
`

export const ModalContent = styled.div<{isMobile?: boolean }>`
	background-color: #fff;
	padding: ${props => (props.isMobile ? '24px' : '30px 40px 40px 40px')};
	border-radius: ${props => (props.isMobile ? '0': '12px')};
	width: ${props => (props.isMobile ? '100%' : '564px')};
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	height: ${props => (props.isMobile ? '100%' : 'auto')};
	max-height: 100%; /* Prevent modal from exceeding viewport height */
	overflow: auto; /* Allow scrolling inside the modal if content overflows */
`


export const ModalBody = styled.div`
	overflow-y: auto;
    max-height: 100%;
	& .header {
		font-family: Libre Franklin;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.21;
		letter-spacing: normal;
		margin-top: 0;
	}

	& .sub-header {
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #333;
	}

	& .margin-32px-32px {
		margin: 32px 0;
	}

	& .margin-16px{
		margin-top: 16px;
	}

	& .bold-link {
		font-weight: bold;
	}
`

export const Separator = styled.div`
	width: 100%;
	border: 1px solid #a18436;
	margin: 32px 0 24px 0;
	`

export const ContentField = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 32px 0 24px 0;

	& .planId {
		font-weight: 500;
		font-size: 14px;
	}

	& .planPrice {
		font-weight: 500;
		font-size: 14px;
	}

	& .totalLabel {
		font-weight: 600;
		font-size: 16px;
	}

	& .totalPrice {
		font-weight: 600;
		font-size: 16px;
	}
`

export const ModalHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 10px;
	`

export const CloseButton = styled.button`
	background: none;
	border: none;
	font-size: 28px;
	cursor: pointer;
	font-weight: bold;
`

export const ModalUpgradeFooter = styled.div`
	display: flex;
	justify-content: center;
	gap: 18px;

	& .cancel {
		height: 49px;
		padding: 14px 30px 15px 30px;
		border-radius: 4px !important;
		border: solid 1px #73333f;
		background-color: #fff;
	}

	& .renew {
		background-color: #73333f;
		color: #fff;
		border: none;
		padding: 16px 31px;
		border-radius: 4px !important;
		cursor: pointer;
		height: 49px;
	}

	@media (max-width: 408px) {
		&  .cancel {
			padding: 14px 20px 15px 20px;
		}

		& .renew {
			padding: 16px 20px;
		}
	}

	@media (max-width: 365px) {
		&  .cancel {
			font-size: 13px;
		}

		& .renew {
			font-size: 13px;
		}
	}

	@media (max-width: 350px) {
		&  .cancel {
			padding: 8px 12px 8px 12px;
		}

		& .renew {
			padding: 16px 15px;
		}
	}

	@media (max-width: 324px) {
		&  .cancel {
			font-size: 12.5px;
		}

		& .renew {
			font-size: 12.5px;
		}
	}
	`