import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
`

export const TitleContainerWeb = styled.div`
  color: white;
  ${commonTextStyle}
  font-weight: bold;
  font-size: 18px;
  z-index: 10;
  position: relative;

  @media (max-width: 1199px) {
    width: 82%;
  }
`

export const TitleContainerMobile = styled.div`
  color: white;
  ${commonTextStyle}
  font-weight: bold;
  font-size: 14px;
  z-index: 10;
  position: relative;
  margin: 0 auto;
  width: 80%;
  height: 100%; 
  display: flex;
  align-items: center; 
  justify-content: center; 
  word-wrap: break-word; 
  word-break: break-word;
  white-space: normal; 
  line-height: 1.2;

  @media (max-width: 991px) and (min-width: 768px) {
    width: 60%;
    font-size: 14px;
    margin-left: calc(8% + (991px - 100vw) * 0.30);
  }

  @media (max-width: 767px) and (min-width: 576px) {
    width: 60%;
    font-size: 14px;
    margin-left: calc(6% + (767px - 100vw) * 0.30);
  }

  @media (max-width: 425px) {
    width: 70%;
    font-size: 14px;
  }

  @media (max-width: 375px) {
    width: 65%;
    padding-right: 5px;
  }

  @media (max-width: 375px) {
    width: 65%;
    padding-right: 5px;
  }

  @media (max-width: 375px) {
    padding-right: 8px;
  }
`