import styled from 'styled-components'
import { RPColors } from '../../../../styles/CSSConstant'

const commonFontAttributes = `
	font-family: Libre Franklin;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
`

export const DetailContainer = styled.div<{ size: string }>`
	margin-bottom: 30px;

	& div.header {
		font-size: ${(props) => props.size};
		font-weight: bold;
		font-style: normal;
		color: #333;
		${commonFontAttributes}
	}

	& div.header-renewal {
		text-align: right;
		font-size: ${(props) => props.size};
		font-weight: bold;
		font-style: normal;
		color: #333;
		${commonFontAttributes}
	}

	& div.value {
		font-size: ${(props) => props.size};
		font-weight: normal;
		font-style: normal;
		color: #333;
		${commonFontAttributes}
	}

	& div.highlight {
		font-size: ${(props) => props.size};
		font-weight: normal;
		font-style: italic;
		color: #777;
		${commonFontAttributes}
	}

	@media (max-width: 600px) {
		flex-direction: row;
		
		& div.header-renewal {
			text-align: left;
		}
	}
`

export const ReduceAddonsContainer = styled.div<{ size: string }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: 600px) {
		flex-direction: row;
		
		& div.header-renewal {
			text-align: left;
		}
	}
`


export const FieldErrorMessage = styled.div`
	display: flex;
    align-items: flex-start;
	margin: 10px 0px 0px 5px;
	margin-bottom: 12px !important;
	font-family: Libre Franklin;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #c50000;
	&.discount {
		float: right;
	}
`

export const FormFieldContainer = styled.div`
	margin-bottom: 20px;

	& div.label {
		font-weight: bold;
		color: #333;
		font-style: normal;
		font-size: 15px;
		${commonFontAttributes};
	}

	& div.field {
		width: 468px;
		margin: 6px 0 0;
		border-radius: 8px;
		background-color: #e5e5e5;
	}

	& div.smaller-field {
		width: 100px;
		margin: 6px 0 0;
		border-radius: 8px;
		background-color: #e5e5e5;
	}
`

export const FormHeaderContainer = styled.div`
	margin-top: 50px;
`

export const BottomButtonsContainer = styled.div`
	display: flex;
	& button {
		margin-right: 10px;
	}
`

export const NotificationContainer = styled.div`
	display: flex;
	justify-content: right;
	width: 100%;

	& span.success {
		color: #fc3c2d;
		font-style: italic;
	}

	& span.notice {
	}
`

export const WhiteButton = styled.button`
	height: 33px;
	padding: 6px 11.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	font-weight: bold;
	cursor: pointer;

	&:hover {
		background-color: #eee;
	}
`

export const OrangeButton = styled.button`
	height: 33px;
	padding: 6px 12.5px;
	border-radius: 8px;
	border: none;
	color: #fff;
	background-color: #73333f;
	font-weight: bold;
	cursor: pointer;

	&:hover {
		background-color: #8E4250;
	}
`

export const NotAddedPaymentMethods = styled.div`
	width: 652px;
	height: 30px;
	margin: 20px 346px 23px 96px;
	font-family: Libre Franklin;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: var(--black);
`


export const OptionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

export const PaymentMethodContainer = styled.div`
	margin-bottom: 15px;
	margin-top: 15px;
	& > div.payment-method{
		display: flex;
		justify-content: start;
		flex-direction: column;
		align-items: start;
		gap: 20px;
	}
`
export const StatusResult = styled.span`
	border: solid 3px #008185;
	display: flex;
	height: 48px;
	width: 650px;
	margin: 0 auto;
	color: #008185;
	font-weight: bold;
	padding-left: 20px;
	flex-wrap: wrap;
    align-content: center;

	@media (max-width: 767px) {
		width: 100%;
	}
`

export const PaymentMethodSection = styled.div<{
	isGrayed: boolean
	height: string
	position: string
}>`
	display: flex;
	justify-content: space-between;
	background-color: ${(props) => (props.isGrayed ? '#fafafa' : '#fff')};

	height: ${(props) => props.height};
	margin: 0px 136px 0px 64px;
	padding: 19px 20px 0 20px;

	border-top-left-radius: ${(props) =>
		props.position === 'top' ? '3px' : '0px'};

	border-top-right-radius: ${(props) =>
		props.position === 'top' ? '3px' : '0px'};

	border-bottom-left-radius: ${(props) =>
		props.position === 'bottom' ? '3px' : '0px'};

	border-bottom-right-radius: ${(props) =>
		props.position === 'bottom' ? '3px' : '0px'};

	border: 1px solid #ccc;

	border-bottom: ${(props) => (props.position === 'top' ? 'none' : '')};

	& div.left {
		display: flex;

		& div.card-expiry {
			& span {
				font-weight: bold;
			}
		}

		& div.icon-container {
			margin-right: 10px;
		}

		& div.card-detail {
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			color: #000;
			${commonFontAttributes}
		}
	}

	& div {
		& span.indicator {
			font-size: 14px;
			font-weight: bold;
			font-style: normal;
			color: #4ecdc4;
			${commonFontAttributes}
		}
	}

	& div.marginized-button {
		margin-right: 10px;
	}
`

export const PaymentHistoryMainContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const PaymentHistoryContainer = styled.div<{isMobile: boolean}>`
	width: ${props => props.isMobile ? '100%' : '80%'};
	justify-content: space-between;
	padding-top: 15px;
	border: solid 1px #a18436;
	border-radius: 12px;
	padding: 20px;
	margin-top: 24px;

	& div.payment-history-details-container {
		display: flex;
		justify-content: space-between;

		& div > div {
			margin-bottom: 10px;
		}
	}

	& div.left {
		margin-left: 18px;

		& span {
			font-weight: bold;
		}
	}

	& div.right {
		margin-right: 18px;

		& span {
			font-weight: bold;
		}

		& div.payment-status {
			color: #4ecdc4;
		}
	}
`

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`
export const SubscriptionRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 12px;

	& div.header-addons {
		font-size: 16px;
		font-weight: bold;
		font-style: normal;
		color: #333;
		${commonFontAttributes}
	}

	& div.value-addons {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		color: #333;
		${commonFontAttributes}
	}

	@media (max-width: 600px) {
		// flex-direction: column;
		justify-content: space-between;

	}

	@media (max-width: 300px) {
		flex-direction: column;
	}
`

export const PaymentMethodRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	border: solid 1px ${RPColors.borderColor};
    padding: 24px;
    border-radius: 12px;

	& .payment-details{
		width: max-content;

		& span{
			margin: 0 10px;
		}
	}

	& .default-label{
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	@media (max-width: 700px) {
		// flex-direction: column;
		// gap: 1rem;

		& .default-label{
			width: 100%;
			padding-left:5px;
			display: flex;
			justify-content: flex-start;
		}

	}

`

export const PaymentMethodActionRow = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	
	& .default-button{
		width: 300px;
	}

	& .delete-button{
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	@media (max-width: 700px) {
		flex-direction: column;
		gap: 1rem;

		& .edit-button{
			text-align: center;
		}

		& .default-button{
			width: 100%;

			& button{
				width: 100%;
			}
		}

		& div{
			width: 100%;
		}

		& .delete-button{
			width: 100%;
			display: flex;
			justify-content: start;

			& div{
				margin:0;
				text-align: center;
			}

		}
	}
`

export const Separator = styled.div<{ isAddon?: boolean }>`
	width: 100%;
	height: 1px;
	margin: ${({ isAddon }) => (isAddon ? '0 0 20px' : '15px 0 0 0')};
	background-color: #a18436;
`

export const RecommendationContainer = styled.div`
	& .button-upgrade {
		margin-top: 32px;
		display: flex;
		justify-content: center;
	}
`

export const RecommendationGuide = styled.div`
	margin-top: 51px;
`

export const RecommendationTable = styled.div`
	width: 100%;
	& .header {
		font-family: Libre Franklin;
		font-size: 22px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		text-align: center;
		color: #311820;
		margin-top: 50px;
	}

	& .sub-header {
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		text-align: center;
		color: #311820;
		margin-top: 8px;
		margin-bottom: 41px;
	}
`

export const RecommendationField = styled.div`
	display: flex;
	justify-content: center;
    flex-direction: column;
    align-items: flex-start;

	& .recommendation {
		margin-top: 25px;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		text-align: left;
		color: #311820;
	}
`

export const UpgradeButton = styled.div`
	width: fit-content;
  	height: auto;
  	padding: 6px 26px;
  	border-radius: 4px;
  	background-color: #73333f;
	font-family: Libre Franklin;
  	font-size: 14px;
  	font-weight: bold;
  	font-stretch: normal;
  	font-style: normal;
  	line-height: 1.2;
  	letter-spacing: normal;
  	text-align: center;
	color: #fff;
	border: none;
	text-transform: uppercase;
	cursor: pointer;

	// &:hover {
	// 	background-color: #8E4250;
	// }

	& .label {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 10px;
		padding: 10px 0px;
		border-radius: 4px;
		background-color: #73333f;
		font-weight: normal;
	}


`

export const SubscriptionSeparator = styled.div<{ title?: string }>`
		width: 120%;
		height: 1px;
		margin: 0 0 20px;
		background-color: ${RPColors.borderColor};
		margin-left: -2rem;
		margin-top: ${props => props.title == 'Update Billing Address' ? '100px' : '30px'};
`
export const SubscriptionHistoryContainer = styled.div`
	justify-content: space-between;
	& div.details {
		& div.title {
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			color: #333;
			${commonFontAttributes}
		}

		& div.date {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			color: #333;
			${commonFontAttributes}
			margin-bottom: 30px;
		}

		& div.payment-detail {
			font-stretch: normal;
			font-style: normal;

			${commonFontAttributes}

			& span.payment-detal-label {
				width: 100%;
				color: #333;
				font-size: 14px;
				font-weight: bold;
			}

			& span.payment-detail-value {
				font-weight: normal;
				color: #a08436;
				
			}
		}
	}

	& div.status {
		font-style: italic;
	}
`




/* Subscription Details May 25, 2023 */
export const NoSubscriptionLabel = styled.div`
	${commonFontAttributes}
	margin: 0 0 43px 0;
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	color: var(--black);	
`

export const SubscriptionInfos = styled.div`
	&.desktop {
		display: flex;
		justify-content: center;
	}
		
	&.mobile {
		width: 80%
	}

	& div.subscription {
		display: flex;
		justify-content: space-between;
		margin-bottom: 11px;
		align-items: center;

		& div.detail-right {
			margin-right: 20px;
		}
	}
	
	& div.subscription-detail {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 21px;
		align-items: flex-start;

		& div.detail-left {
			margin-left: 20px;
		}

		& div.detail-right {
			margin-right: 20px;
		}

		& div > span.normal-font {
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
		}

		& div > span.bolded-font {
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: bold;
			font-stretch: normal;
			font-style: bold;
			line-height: 1.2;
			letter-spacing: normal;
			text-align: right;
			color: #333;
		}
	}

	& .trial {
		margin: 20px 0 0;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	}
`

export const BuySubscriptionButton = styled.button`
	height: 33px;
	padding: 6px 11.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
`
export const BuySubscriptionLabel = styled.span`
${commonFontAttributes}
	width: 130px;
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: var(--black);
`
export const BuyAddOnsButton = styled.div`
	width: auto;
	height: 33px;
	padding: 6px 13.5px;
	border-radius: 8px;
	background-color: #73333f;
	text-align: center;
	cursor: pointer;

	&:hover {
		background-color: #8E4250;
	}
`

export const BuyAddOnsLabel = styled.span`
${commonFontAttributes}
	width: 179px;
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: #fff !important;
`
export const ChangePlanButton = styled.button`
	width: 100%;
	height: 33px;
	padding: 6px 13.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	cursor: pointer;

	&:hover {
		background-color: #efefef;
	}
`


export const CancelRenewalButton = styled.div`
	height: 33px;
	margin-right: 10px;
	padding: 6px 13.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	cursor: pointer;
`

export const ChangePlanLabel = styled.span`
${commonFontAttributes}
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: var(--black);
`

export const UpgradeToCommerciallABEL = styled.button`
${commonFontAttributes}
	width: 179px;
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: var(--white-three);
`

/* Manage Payment Methods May 25, 2023 */
export const AddPaymentButton = styled.button`
	padding: 10px;
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 4px !imporant;
	border: none;
	color: #fff;
	background-color: #73333f;
	cursor: pointer;

	&:hover {
		background-color: #8E4250;
	}
`
export const PageHeader = styled.div`
${commonFontAttributes}
	font-size: 22px;
	font-weight: bold;
	font-style: normal;
	color: #333;
`
export const CreditCardInformationContainer = styled.div`
	padding: 20px;
	& div.expiry-date-container{
		display: flex;
		justify-content: space-between;
		
		&.expiry-date {
			width: 224px !important;
		}
	}

	& select.select-payment-method{
		// width: 468px;
		height: 33px;
		margin: 6px 0 0;
		padding: 6px 12px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: #e5e5e5;
		color: #ccc;
	}

	& div.form-label {
		// font-size: 16px;
		font-weight: bold;
		color: #333;
		margin-bottom: 5px;

		& span {
			color: red;
		}
	}

	& span.im-not-robot {
		width: 652px;
		height: 24px;
		margin: 20px 0;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: italic;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--black);
	}
	
	& .desktop-container {
		width: auto;
		margin: 0 auto;
		display: flow;
		justify-content: center;
		align-items: center;
		width: 500px
	}

	& .btn-separator {
			display: flex;
			justify-content: space-between;
		}

	@media only screen and (max-width: 480px) {
		& .btn-separator {
			display: block;
			flex-direction: row;
		}
	}
`

export const CardInformationField = styled.input`
  	height: 33px;
  	margin: 6px 0 0;
  	padding: 6px 11.5px 6px 12px;
  	border-radius: 8px;
  	border: solid 1px #ccc;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;

	&[disabled]{
		background-color:#EDEDED;
		color:rgb(0,0,0,0.3);
	}
`

export const CardInformationFieldMuted = styled.input`
	width: 468px;
	height: 33px;
	margin: 6px 0 0;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: #e5e5e5;
	color: #ccc;
  	padding: 6px 11.5px 6px 12px;
  	border: solid 1px #ccc;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
`

export const BackButton = styled.button`
	width: 104px;
	height: 33px;
	// margin: 19px 359px 0 0;
	padding: 6px 13px 6px 12px;
	border-radius: 8px;
	border: solid 1px #ccc;
	font-weight: normal;
	color: #000;

	background-color: var(--white-three);
	&.mobile {
		padding-right: 12px !important;
	}

	& .img-arrow{
		transform: scaleX(-1);
	}
`

export const Cancel = styled.span`
${commonFontAttributes}
	width: 53px;
	height: 21px;
	margin: 0 0 0 12px;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	color: var(--black);
`

export const BackIcon = styled.span`
${commonFontAttributes}
	width: 53px;
	height: 21px;
	margin: 0 0 0 12px;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	color: var(--black);
`

export const SavePaymentButton = styled.button`
	height: 33px;
	padding: 6px 11.5px;
	border-radius: 8px;
	border: none;
	color: #fff;
	background-color: #73333f;
	font-weight: bold;
	cursor: pointer;

	&:hover {
		background-color: #8E4250;
	}
`
export const PaymentItemContainer = styled.div`
	width: 100%;
	display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const MySubscriptionModalContainer = styled.div<{isMobile: boolean, isOpen: boolean}>`
	display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
	justify-content: ${({ isMobile }) => (isMobile ? 'initial' : 'center')};
	align-items: ${({ isMobile }) => (isMobile ? 'initial' : 'center')};
	&.modal {
		/* add styles for modal class here */
	}
	&.fade {
		/* add styles for fade class here */
	}
`
export const ModalCloseButton = styled.span<{ isMobile: boolean}>`
    color: #000000;
    padding-right: 24px;
    padding-top: 30px;
    display: flex;
    justify-content: end;
	cursor: pointer;

	& span {
		font-weight: bold;
		font-size: 26px;
		font-family: sans-serif;
	}

	& img {
		width: 20px;
		height: 20px;
		margin-right: 8px;
		filter: invert(100%) brightness(0);
	}
`
export const SecurePaymentText = styled.p<{isMobile: boolean}>`
	color: ${RPColors.darkPrimary};
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	margin  ${({ isMobile }) => (isMobile ? ' 18px 0 24px 0' : '24px')};
`
export const ModalTitle = styled.h3<{isMobile: boolean, title: string}>`
	font-size: 24px;
	text-align: left;
	color: ${RPColors.darkPrimary};
	font-weight: 600;
	padding-bottom: ${props => props.title == ('Update Billing Address') ? '20px' : '12px'};
	margin-top: ${props => props.isMobile ? '50px' : '0px'};
`
export const PaymentMethodCardContainer = styled.div<{isMobile: boolean}>`
	padding: ${props => props.isMobile ? '' : '24px'};
	border-radius: ${props => props.isMobile ? '' : '12px'};
	border: ${props => props.isMobile ? '' : `solid 1px ${RPColors.borderColor}`};
	width: ${props => props.isMobile ? '100%' : '80%'};
	display: block;

	@media (max-width: 1000px) {
		width: 100%;
		height: 100%;
	}
`
export const AddOnsDetails = styled.div<{isMobile: boolean}>`
  display: ${props => props.isMobile ? 'grid' : 'flex'};
  flex-direction: ${props => props.isMobile ? '' : 'row'};
  justify-content: ${props => props.isMobile ? '' : 'space-between'};
  justify-self: ${props => props.isMobile ? 'center' : ''};
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  margin-top: ${props => props.isMobile ? '20px' : '0'};
  margin-bottom: ${props => props.isMobile ? '20px' : '0'};

  & .span-container {
	display: flex;
    justify-content: space-between;
  }
`

export const separator = styled.div`
	height: 1px;
	margin: 20px 0 40px 0px;
	background-color: #ccc;
`

export const BillingAddressContainer = styled.div<{isMobile: boolean}>`
	padding: ${props => props.isMobile ? '' : '24px'};
	border-radius: ${props => props.isMobile ? '' : '12px'};
	border: ${props => props.isMobile ? '' : `solid 1px ${RPColors.borderColor}`};
	width: ${props => props.isMobile ? '100%' : '80%'};
	margin-top: 32px;

	@media (max-width: 1000px) {
		width: 100%;
		height: 100%;
	}
`
export const PaymentMethodCardInfo = styled.div`
	// width: 652px;
	// height: 53px;

	@media (max-width: 1000px) {
        // width: 100%;
        // height: 100%;
    }
`
export const PaymentMethodCardTitle = styled.p`
	${commonFontAttributes}
	font-size: 24px;
	font-weight: bold;
	margin-top: 32px;
	text-align: left;
	color: ${RPColors.darkPrimary}
`
export const BillingMethodCardTitle = styled.p`
	${commonFontAttributes}
	font-size: 24px;
	font-weight: bold;
	margin-top: 32px;
	color: ${RPColors.darkPrimary}
`
export const BillingMethodForm = styled.span`
	${commonFontAttributes}
	font-size: 16px;
	margin-top: 32px;
	font-weight: 500;
	display: grid;
	flex-direction: column;
	line-height: 24px;
	color: ${RPColors.darkPrimary}
`
export const PaymentMethodCardSelection = styled.div`
	width: 649px;
	height: 74px;
	margin: 20px 0 0;
	padding: 21px 20px 20px;
	border-radius: 8px;
	border-top: solid 1px #ccc;
	background-color: #fafafa;
	
	@media (max-width: 1000px) {
		width: 100%;
		height: 100%;
	}
`
export const PaymentMethodCardEdit = styled.div`
	width: 55px;
	height: 33px;
	margin: 0 10px 0 0;
	padding: 6px 6px;
	border-radius: 8px;
	background-color: #73333f;
`
export const PaymentMethodCardEditLabel = styled.button`
${commonFontAttributes}
	height: 100%;
	border-radius: 2px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: #fff;
	border: none;
	background-color: #73333f;
	font-weight: bold;
	cursor: pointer;
`
export const PaymentMethodCardEditLabelMute = styled.button`
	height: 100%;
	border-radius: 2px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: #ccc;
	border: none;
	background-color: fff;
	font-weight: bold;
	border: 1px solid #ccc;
	outline: none;
	cursor: not-allowed;
` 

export const PaymentMethodCardDefaultLabelMute = styled.span`
${commonFontAttributes}
	width: 135px;
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: #ccc;
	cursor: pointer;
`
export const PaymentMethodCardDefault = styled.div`
	width: 164px;
	height: 33px;
	margin: 0 308px 0 -10px;
	padding: 6px 14.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-five);
`
export const PaymentMethodCardDefaultLabel = styled.span`
${commonFontAttributes}
	width: 135px;
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: var(--black);
	cursor: pointer;
`
export const PaymentMethodCardDelete = styled.div`
	width: 75px;
	height: 33px;
	margin: 0 0 0 65px;
	padding: 4px 6px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
`
export const PaymentMethodCardDeleteLabel = styled.button`
${commonFontAttributes}
	width: 49px;
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: #000;
	border: none;
	background-color: #fff;
	font-weight: bold;
	cursor: pointer;
`
export const PaymentMethodCardUser = styled.span`
${commonFontAttributes}
	width: 443.9px;
	height: 24px;
	margin: 0 75.1px 28px 9.7px;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	color: #000;
`

export const ClickableDiv = styled.div`
	border: 1px solid #a18436;
	height: 24px;
	width: 24px;
	border-radius: 100%;
	cursor: pointer;
	display: grid;
`

export const InnerButton = styled.div<{isClicked: boolean}>`
	height: 16px;
	width: 16px;
	border-radius: 50%;
	cursor: pointer;
	background-color: ${props => props.isClicked ? '#a18436' : 'transparent'};
	justify-self: center;
	align-self: center;
`
export const PaymentMethodActionContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 32px;
`

export const DeleteCard = styled.p`
${commonFontAttributes}
	color: ${RPColors.darkPrimary};
	font-size: 16px;
	font-weight: 500;
	text-decoration: underline;
	cursor: pointer;

`
export const UpdateButton = styled.p`
${commonFontAttributes}
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
    background-color: ${RPColors.buttonColor};
    padding: 10px;
    border-radius: 4px;
	cursor: pointer;
	margin-left: 40px;
`
export const AddSubscriptionActionContainer = styled.div<{ isMobile?: boolean }>`
	display: flex;
	justify-content: center;
	gap: 5px;
	padding: 20px 0 10px 0;
	position: ${({ isMobile }) => (isMobile ? 'sticky' : 'static')};
	bottom: 0;
	width: 100%;
`

export const CancelButton = styled.p`
${commonFontAttributes}
	color: ${RPColors.darkPrimary};
	font-size: 14px;
	font-weight: 600;
    padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
    border-radius: 4px;
	border: solid 1px ${RPColors.darkPrimary};
	cursor: pointer;
	margin-bottom: 0px;
`
export const AddButton = styled.p`
${commonFontAttributes}
	color: #ffffff;
	font-size: 14px;
	font-weight: 400;
    background-color: ${RPColors.buttonColor};
	padding-left: 35px;
	padding-right: 35px;
    border-radius: 4px;
	margin-left: 12px;
	align-content: center;
	cursor: pointer;
	margin-bottom: 0px;
`
export const PaymentMethodExpire = styled.span`
${commonFontAttributes}
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	color: #000;

	& span.normal-style{
		font-weight: normal;
	}
`

export const PaymentMethodCardContainerDefault = styled.div`
${commonFontAttributes}
	font-size: 14px;
	font-weight: bold;
	color: #008185;

	& div {
		text-align: right;
	}

	@media(max-width: 700px){
		& div {
			text-align: left;
			padding-left: 1rem;
		}
	}
`

export const ManagePaymentMethods = styled.span`
${commonFontAttributes}
	width: 209px;
	height: 14px;
	font-size: 12px;
	font-weight: bold;
	font-style: normal;
	line-height: normal;
	color: #73333f;
`

export const PaymentMethodCardLogo = styled.div`
	width: 37px;
	height: auto;
	// margin: 0px 10px 10px 5px;
	object-fit: contain;

	@media (max-width: 700px) {
        width: 37px;
		height: auto;
		// margin: 10px 20px;
    }
`

export const PaymentMethodEmptyContainer = styled.div<{isMobile: boolean}>`
	border: ${(props) => (props.isMobile ? 'none' : `solid 1px ${RPColors.borderColor}`)};
	border-radius: 12px;
	padding: ${(props) => (props.isMobile ? '0px' : '32px')};
	width: 80%;
	display: grid;
	margin: 0 auto;
	margin-top: 20px;
	
	@media (max-width: 1000px) {
		margin-top: 2rem;
	}

	& .deleted-success {
		margin-bottom: 20px;
	}
`

export const ProceedButton = styled.button`
	padding: 12px;
	border-radius: 4px !important;
	border: none;
	background-color: #73333f;
	color: white;

	&:hover {
		background-color: #8E4250;
	}

	@media (max-width: 407px) {
		padding: 12px !important;
	}
	
	@media (max-width: 519px) {
		padding: 12px;
	}
`

export const CloseButton = styled.button`
	padding: 16px;
	border-radius: 4px !important;
	border: none;
	background-color: #FFF;
	
	@media (max-width: 519px) {
		padding: 14px;
	}
`

/* Subscription History May 26, 2023 */
export const SubsHistoryPlanLabel = styled.span`
${commonFontAttributes}
	width: 624px;
	height: 24px;
	padding: 0 12px 0 0px;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	color: var(--black);
`

export const SubsHistoryStatusLabel = styled.span`
${commonFontAttributes}
	width: 100px;
	height: 24px;
	font-size: 16px;
	font-weight: normal;
	font-style: italic;
	text-align: right;
	color: var(--black);
`

export const SubsHistoryDateLabel = styled.span`
${commonFontAttributes}
	width: 624px;
	height: 21px;
	margin: 8px 3px;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	color: var(--black);
`

export const SubsHistoryPaymentLabel = styled.span`
${commonFontAttributes}
	display: flex;
	width: 100%;
	margin: 10px 3px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	color: #a08436;
`

export const SubscriptionHistoryWrapper = styled.div`
	width: 652px;
	height: 137px;
	margin: 20px 346px 0 97px;
	padding: 20px 0 0;
	border-radius: 8px;
	background-color: var(--white-three);
`

/* Payment History May 26, 2023 */
export const PaymentHistoryStatusLabel = styled.span<{isMobile: boolean}>`
${commonFontAttributes}
	font-size: ${(props) => (props.isMobile ? '12px' : '14px')};
	font-weight: bold;
	font-style: normal;
	color: #008185;
`

export const PaymentHistoryDateLabel = styled.span<{isMobile: boolean}>`
${commonFontAttributes}
	font-size: ${(props) => (props.isMobile ? '12px' : '14px')};
	font-weight: normal;
	font-style: normal;
	color: var(--black);
`

export const PaymentHistoryPaymentLabel = styled.span<{isMobile: boolean}>`
${commonFontAttributes}
	font-size: ${(props) => (props.isMobile ? '12px' : '14px')};
	font-weight: bold;
	font-style: normal;
	color: var(--black);
	width: max-content;
`

export const PaymentHistoryUsdLabel = styled.span<{isMobile: boolean}>`
${commonFontAttributes}
	width: max-content;
	font-size: ${(props) => (props.isMobile ? '12px' : '14px')};
	font-weight: bold;
	font-style: normal;
	line-height: 1.31;
	text-align: right;
	color: var(--black);

	@media (max-width: 600px){
		width: max-content;
	}
`

export const PaymentHistoryCardAccountLabel = styled.span<{isMobile: boolean}>`
${commonFontAttributes}
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	color: var(--black);

	@media (max-width: 600px){
	}
`

export const PaymentHistoryDownloadButton = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-start;
	margin-top: 16px;

	// @media (max-width: 1000px){
	// 	justify-content: flex-start;
	// }
`
export const PaymentHistoryDownloadLabel = styled.button<{isDisable?: boolean}>`
${commonFontAttributes}
	background-color: ${({ isDisable }) => (isDisable ? '#fafafa' : '#73333f')};
	padding: 6px;
	border-radius: 4px !important;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: ${({ isDisable }) => (isDisable ? '#ccc' : 'white')};
	border:  ${({ isDisable }) => (isDisable ? '1px solid #ccc' : 'none')};;
	cursor: ${({ isDisable }) => (isDisable ? 'default' : 'pointer')};

	&:hover {
		background-color: ${({ isDisable }) => (isDisable ? '' : '#8E4250')};
	}
`
export const SeparatorPercent = styled.div`
	width: 100%;
	height: 1px;
	margin: 0 0 20px;
	background-color: #ddd;
`

/* Cancel Renewal Modal May 25, 2023 */

export const CancelRenewalHeader = styled.span`
	& span.img-close {
	width: 20px;
	height: 20px;
	margin: 2px 0 2px 20px;
	object-fit: contain;
	float: right;
	// background-color: #ccc;
	cursor: pointer;
	}
`

export const CancelRenewalTitle = styled.span`
${commonFontAttributes}
	width: 500px;
	height: 24px;
	margin: 0 20px 0 0;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	color: #000;
`

export const CancelRenewalBody = styled.span`
${commonFontAttributes}
	width: 540px;
	height: 48px;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	color: #000;
	display: inline-block;
	text-align: left;
`
export const CancelRenewalSeparatorContainer = styled.div`
	width: 100%;
`
export const CancelRenewalSeparator = styled.div`
	width: 598px;
	height: 1px;
	margin: 0px 0 20px;
	background-color: #e5e5e5;
`
export const CancelRenewalCancel = styled.div`
	width: 78px;
	height: 33px;
	margin: 0 10px 0 0;
	padding: 5px 6px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
`
export const CancelRenewalCancelButton = styled.button`
${commonFontAttributes}
	width: 54px;
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: var(--black);
	border: none;
	background-color: var(--white-three);
`
export const CancelRenewalProceed = styled.div`
	width: 89px;
	height: 33px;
	margin: 0 0 0 0px;
	padding: 5px 8px;
	border-radius: 8px;
	background-color: #73333f;
`
export const CancelRenewalProceedButton = styled.button`
${commonFontAttributes}
	width: 62px;
	height: 21px;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	color: #fff;
	border: none;
	background-color: #73333f;
`


// font-family: Libre Franklin;
// font-stretch: normal;
// line-height: 1.2;
// letter-spacing: normal;


export const TabbedMneu = styled.div`
	display: flex;
	& div.bar {
	}
`

export const TabbedMenuContainer = styled.div``

export const TabbedMenu = styled.div``

export const TabbedMenuItem = styled.div<{ isActive: boolean }>`
	display: flex;
	height: 40px;
	cursor: pointer;

	& div.bar {
		width: 2px;
		height: 40px;
		margin: 0 10px 0 0;
		background-color: ${(props) => (props.isActive ? '#333' : '#fff')};
	}

	& div.menu {
		font-size: 16px;
		padding: 8px;

		font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
		${commonFontAttributes}

		&:hover {
			color: #a08436;
		}
	}
`
export const ModalDeleteContainer = styled.div`
max-width: 600px;

    & .delete-container {
        & .header {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
            padding: 20px 30px;
            border-bottom: 1px solid #e5e5e5;

            & .delete-tab {
                display: flex;
                gap: 8px;

                & a {
                    color: black;
                    text-decoration: none;
                    font-size: 16px;
                }
            }

            & .modal-close {
                cursor: pointer;
                font-size: 15px;
            }
        }

        & .tab-content {
            padding: 30px;
            padding-top: 10px;
        }
    }
    

    & .modal-error {
        background-color: rgb(224, 26, 0);
        padding: 12px;
        text-align: center;

        border-radius: 0  0 5px 5px;

        & span {
            font-size: 12px;
            color: white;
        }
    }

	& .img-close{
		cursor: pointer;
		
	}
	`

export const ModalFooter = styled.div`
	padding: 1rem;
	border-top: 1px solid #e5e5e5;
	display: flex;
	align-items: center;
	gap: 1rem;
	padding-right: 20px;
	justify-content: flex-end;
`
export const SwitchContainer = styled.div<{isChecked: boolean}>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;

	& .onLabel{
		width: 23px;
		height: 24px;
		margin: 0 10px 0 0;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: ${(props) => (props.isChecked ? 'normal' : 'bold')};;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: ${(props) => (props.isChecked ? '#777' : '#73333f')};
	}

	& .offLabel{
		width: 29px;
		height: 24px;
		margin: 0 0 0 10px;
		font-family: Libre Franklin;
		font-size: 16px;
		font-weight: ${(props) => (props.isChecked ? 'bold' : 'normal')};
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: ${(props) => (props.isChecked ? '#73333f' : '#777')};
	}

	 & .switch-container{
		display: flex;
		flex-direction: row;
	}

	@media (max-width: 600px) {
		flex-direction: row;
		justify-content: space-between;

		& .renewal-group{
			text-align: left;
			flex-direction: row;
			justify-content: space-between;
		} 
		
	}

	@media (max-width: 300px) {
		flex-direction: column;
	}
	
`


export const SwitchToggleContainer = styled.label<{disabled: boolean}>`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  margin: 0;
`

export const Slider = styled.span`
  width: 48px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #73333f;
  border-radius: 34px;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 1px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: .4s;
  }
`

export const InputCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #ccc;
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px #2196F3;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`

export const ReduceAddonsButton = styled.button`
	width: 135px;
	height: 33px;
	padding: 6px 13.5px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	cursor: pointer;

	& p {
		${commonFontAttributes}
		width: 112px;
  		height: 21px;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		text-align: center;
		color: var(--black);
		text-align: center;
	}

	&:hover {
		background-color: #efefef;
	}
`

export const CardContainer = styled.div<{ isMobile: boolean }>`
	width: ${({ isMobile }) => (isMobile ? '100%' : '80%')};
    padding: 32px;
    margin: auto;
	margin-top: 2rem;
    padding-bottom: 32px;
    border-radius: 12px;
    border: solid 1px ${RPColors.borderColor};
    background-color: 'white';
`

export const CardBody = styled.div<{ isMobile: boolean }>`
    & .head-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
		text-align: ${({ isMobile }) => isMobile ? 'center' : 'start'};

        & h3 {
            margin-bottom: 1rem;
            font-size: 20px;
            font-weight: 600;
        }

        & p {
            margin: 0;
            font-size: 16px;
        }
    }

	& .cc-cards {
		display: flex;
		gap: 0.7rem;
		align-items: center;
		margin-bottom: 1.5rem !important;
		margin-top: 1rem !important;
		justify-content: center;

		& img {
			width:  ${({ isMobile }) => isMobile ? '60px' : '70px'};
			height: auto;
		}
	}

    & .form {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        margin-bottom: 10px;

        & .first-input {
            width: 100%;
            display: flex;
            flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
            justify-content: space-between;
            align-items: start;
            gap: ${({ isMobile }) => (isMobile ? 0 : '16px')};

            & .password-input {
                width: 100%;
                position: relative;

                & img {
                    z-index: 0;
                    position: absolute;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    right: 10px;
                    bottom: 15px;
                }
            }

			& span {
				font-weight: 500;
			}
        }

		& .addon-input {
			border: 1px solid ${RPColors.borderColor};
			border-radius: 8px;
			padding: 1rem;
			margin-bottom: 1rem !important;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			

			& .addon-text {
				width: 100%;
				font-size: 16px;
				display: flex;
				align-items: start;
				justify-content: center;
				gap: 0.5rem;
				flex-direction: column;
			}
		}

		& .text-field {
			width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: start;
            gap: 16px;

			& span {
				font-weight: 800;
			}
		}

        & .action-input {
            margin: 0.7rem 0 1.5rem 0 !important;
            width: 100%;import { isMobile } from 'react-device-detect';

            display: flex;
            flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
            justify-content: space-between;
            align-items: end;
            gap: 16px;

            & .recaptcha-style {
                text-align: ${({ isMobile }) => isMobile ? 'center' : 'start'};
            }
        }

		& actions {

		}
    }

	& .footer {
		text-align: center;

		& p {
			margin: 0;
		}
	}
`

export const ContentDetail = styled.p`
	font-size: 16px;
	font-weight: 500;

	& .title {
		font-weight: bold;
	}
`

export const AutoRenewContainer = styled.div`
	font-size: 14px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	& .title {
		font-weight: bold;
	}
	& .span {
		font-weight: bold;
	}

	& .onLabel {
		font-weight: 500;
	}
`

export const ModalWrapper = styled.div<{ isOpen: boolean, isMobile: boolean }>`
	display: ${props => (props.isOpen ? 'flex' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
			align-items: flex-start; 
	padding-top: ${props => (props.isMobile ? '0px' : '100px')} 
	`

export const ModalContent = styled.div<{ width?: number, isMobile?: boolean }>`
	background-color: #fff;
	padding: ${props => (props.isMobile ? '24px' : '40px')};
	border-radius: 12px;
	width: ${props => (props.width ? `${props.width}px` : props.isMobile ? '100%' : '564px')};
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	height: ${props => (props.isMobile ? '100%' : 'auto')}
	`

export const ModalHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-bottom: 10px;
	margin-bottom: 10px;
	`

export const CloseButtonUpgrade = styled.button`
	background: none;
	border: none;
	font-size: 28px;
	cursor: pointer;
	font-weight: bold;
	`

export const ModalBody = styled.div<{isMobile?: boolean}>`
	margin-bottom: 32px;

	& .title {
		font-family: Libre Franklin;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.21;
		letter-spacing: normal;
		color: #311820;
		width: ${props => (props.isMobile ? '100%' : '431px')}
	}

	& .sub-title {
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.21;
		letter-spacing: normal;
		color: #311820;
		margin-top: 12px;
	}
	`

export const ModalUpgradeFooter = styled.div`
	display: flex;
	justify-content: center;

	& .close-upgrade {
		background-color: white;
		color: black;
		border: none;
		padding: 16px 20px;
		border-radius: 4px;
		cursor: pointer;
		margin-left: 10px;
		font-weight: 600;
		text-decoration: underline;
		height: 49px;
	}

	& .proceed-upgrade {
		background-color: #73333f;
		color: #fff;
		border: none;
		padding: 16px 31px;
		border-radius: 4px !important;
		cursor: pointer;
		margin-left: 10px;
		height: 49px;
	}
	`

export const SelectionContainer = styled.div<{ isMobile: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #a18436;
	margin-top: 16px;
	padding: ${props => (props.isMobile ? '24px' : '16px')};
	border-radius: 4px;
	flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
	align-items: ${props => (props.isMobile ? 'baseline' : 'center')};

	& .seats {
		height: 57px;
		width: ${props => (props.isMobile ? '100%' : '130px')};
		margin-top: ${props => (props.isMobile ? '16px' : '0px')};
	}

	& .notes {
		height: 57px;
		width: ${props => (props.isMobile ? '100%' : '180px')};
		margin-top: ${props => (props.isMobile ? '16px' : '0px')};
	}
`

export const SelectionLabel = styled.div`
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.19;
	letter-spacing: normal;
	color: #311820;
`

export const SelectionSubLabel = styled.div`
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	color: #311820;
	margin-top: 4px
`
