/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { APP_CONFIG, algoliaFacetTitles } from '../configs'

const keyPropReference = {
	country: { key_prop: 'name', value_prop: 'name' },
	wine_type: { key_prop: 'label', value_prop: 'value' },
	wine_appellation_locale: { key_prop: 'name', value_prop: 'name' },
	wine_appellation_location: { key_prop: 'name', value_prop: 'name' },
	wine_color: { key_prop: 'label', value_prop: 'value' },
	wine_variety: { key_prop: 'label', value_prop: 'value' },
	issue: { key_prop: 'id', value_prop: 'title' },
	wine_dryness: { key_prop: 'label', value_prop: 'value' },
	producer: { key_prop: 'name', value_prop: 'name' },
	wineGroup: { key_prop: 'name', value_prop: 'name' },
	vintage: { key_prop: 'vintage', value_prop: 'vintage' },
	wine_appellation_region: { key_prop: 'name', value_prop: 'name' },
	wine_appellation_site: { key_prop: 'id', value_prop: 'name' },
	wine_maturity: { key_prop: 'label', value_prop: 'value' },
	wine_appellation_country: { key_prop: 'name', value_prop: 'name' },
	author: { key_prop: 'id', value_prop: 'name' },
	publication: { key_prop: 'id', value_prop: 'title' },
	region: { key_prop: 'name', value_prop: 'name' },
}

type propOfInterest = {
	key_prop: string
	value_prop: string
}

export function ExtractOptionValues<FilterObject>(
	filter: FilterObject,
	propToLookoutFor: string
): propOfInterest {
	const stringifiedReference = JSON.stringify(keyPropReference)
	return JSON.parse(stringifiedReference)[propToLookoutFor]
}

// export function GetPropertyToFilterFor<FilterObject>(
// 	filter: FilterObject
// ): string {
// 	return Object.keys(filter)[1] != 'count'
// 		? Object.keys(filter)[1]
// 		: Object.keys(filter)[0]
// }

export function GenerateQueryForFilters(
	keywordPair: string,
	filterReference: string
): string {
	const container = [`filter=${filterReference}`]

	if (keywordPair && keywordPair.split('=').length > 1) {
		container.push(keywordPair)
	}

	return container.join('&')
}

export const getFiltersViewAllKey = (reference: string): string => {
	if (reference) return reference.replace('.', '_')
	return ''
}
export const updateFacetsFromAPI = (oldFacets: any, newFacets: any, reference: string): any  =>  {
	if(Object.keys(oldFacets).length === 0) {
		return newFacets
	}else{
		let facets = newFacets
		if(reference !== '') {
			facets = { ...newFacets, [reference]: oldFacets[reference] }
		}
		return facets
	}
}

export const getFilters = (data: any) => [data]

export const getFilterOptions = (data: any, reference: string) => {
	const newOptions = []
	for (const key in data) {
		newOptions.push({ label: key, value: data[key] })
	}

	if (reference === 'vintage') {
		newOptions.reverse()
	}

	if (reference === 'issue_appearances') {
		newOptions.sort((a, b) => b.value - a.value)
	}

	return newOptions
}

export const getAdvanceSearchOptions = (data: any, reference: string) => {
	const newOptions = []
	for (const key in data) {
		newOptions.push({ label: key, value: lowerCaseId(`${reference}:${key}`) })
	}

	if (reference === 'vintage') {
		newOptions.reverse()
	}
	return newOptions
}

export const removeObjDuplicates = (item: any, id: any) => {
	const setObj = new Set() // create key value pair from array of array
	const result = item.reduce((acc: any, item: any) => {
		if (!setObj.has(item[id])) {
			setObj.add(item[id])
			acc.push(item)
		}
		return acc
	}, [])//converting back to array from mapobject
	return result
}


export function facetsFromQueryString(queryString: any): any {
	const query = Object.keys(queryString)
	const facets = [] as any
	query.map(ele => {

		if (APP_CONFIG.SEARCH.QUERY_STRING_FACETS.includes(ele)) {
			facets.push([lowerCaseId(`${ele}:${queryString[ele]}`)])
			return ele
		}

	})


	return facets
}

export function filtersFromQueryString(queryString: any): any {
	const query = Object.keys(queryString)
	let filters = {} as any
	query.map(ele => {
		if (APP_CONFIG.SEARCH.QUERY_STRING_FACETS.includes(ele)) {
			filters = { ...filters, [ele]: [lowerCaseId(`${ele}:${queryString[ele]}`)] }
			return ele
		}
	})
	return filters
}


export const lowerCaseId = (id: string): string => {
	return id.toLowerCase()
}

export const getFilterKey = (filters: any, type: 'advance-search' | 'search'): any => {
	let options = [] as any
	options = Object.keys(algoliaFacetTitles()).map(data => {
		if (filters[data] !== undefined && type === 'advance-search') {
			return data
		}

		if(filters[data] !== undefined && type === 'search' && data !== 'price_low' && data !== 'rating_computed'){
			return data
		}
	})

	return options.filter((data: any) => { return data !== undefined })
}

export const convertFacetsToArray = (facetValues: any): any => {
	const newValues = [] as any

	if (facetValues) {
		Object.keys(facetValues).map(data => {
			if (facetValues[data] !== undefined) {
				newValues.push({ label: data, value: facetValues[data] })
			}
		})

		return newValues
	} else {
		return []
	}
}


/**
 * Returns the corresponding filter title derrived from the config
 * @param reference strings
 * @returns 
 */
export function getFilterTitle(reference: string): string {
	const stringifiedTitles = JSON.stringify(algoliaFacetTitles())
	return JSON.parse(stringifiedTitles)[reference]
}

export const updateFiltersFromSearch = (filteredOption: any, updatedFilters: any, newValue: string, label:string) =>  {
	// let newFilters = filteredOption as any
	let newFilters = [...filteredOption]
	const index = filteredOption.findIndex((data: any) => data.value === newValue)

	if (index > -1) {
		if (!filteredOption[index].isSelected) {
			const arrays = [...newFilters]

			arrays[index] = { value: newValue, label: label, isSelected: true }

			newFilters = arrays
		} else {
			if (filteredOption.length > 100) {

				if (!filteredOption[index].isFromSearch) {
					// newFilters[index] = { value: newValue, label: label, isSelected: false }
					newFilters = newFilters.map((item: any, i: number) =>
						i === index ? { value: newValue, label: label, isSelected: false } : item
					)
					
				} else {
					newFilters = newFilters.filter((ele: any) => { return ele.value !== newValue })
				}

			} else {

				const arrays = [...newFilters]
				arrays[index] = { value: newValue, label: label, isSelected: false }
				newFilters = arrays
			}
		}
	} else {
		const x = updatedFilters.filter((ele: any) => { return ele.isSelected === true && ele.value === newValue })
		newFilters = x.concat(newFilters)
	}

	return newFilters
}