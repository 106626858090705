/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { CloseButton, NoticeModalContainer, ProceedButton } from './styles'
import { Link } from 'react-router-dom'

function NoticeModal(props: { isOpen: boolean, onClose?: (result: any) => void, messageBody: string, title: string, isBlocked: boolean, isPaymentUpdate?: boolean }): ReactElement {

	const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault()
	}

	const handleClose = (res: boolean) => {
		if(props.onClose)
			props.onClose(res)
	}

	return (
	
		<div className={props.isOpen ? 'modal' : 'modal fade'} style={{ display: props.isOpen ? 'block' : 'none'}} onContextMenu={handleContextMenu}>
			<NoticeModalContainer>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header" style={{display: 'flow'}}>
							{!props.isBlocked && 
								<button type="button" className="close" onClick={() => handleClose(false)}>
									<span aria-hidden="true">&#10005;</span>
								</button>
							}
							<h3 className="modal-title" style={{ fontSize: '16px' }}>{props.title}</h3>
						</div>
						
						<div className="modal-body" style={{ padding: 20 }}>
							<div className="subscribe-form subscribe-form--horizontal max-width-sm">
								<p className="typescale-1" style={{ fontSize: '15px' }}>{props.messageBody}</p>
							</div>
						</div>									
						<div className="modal-footer">
							{props.isBlocked ?
								<span>
									{props.isPaymentUpdate ?
										<ProceedButton isPaymentUpdate={props.isPaymentUpdate} onClick={() => window.location.reload()}>
											BACK TO MY SUBSCRIPTIONS
										</ProceedButton>
										:
										<Link to={'/my-subscription/subscription-details'}>
											<ProceedButton> Return to Manage Subscription </ProceedButton>
										</Link>
									}
								</span>
								:
								<span>
									<CloseButton onClick={() => handleClose(false)} > CANCEL </CloseButton>&nbsp;
									<ProceedButton onClick={() => handleClose(true)} > CONFIRM </ProceedButton>
								</span>
							}
							
						</div>
							
					</div>
				</div>
			</NoticeModalContainer>
		</div>

	)
}

export default NoticeModal

NoticeModal.defaultProps = {
	isCloseOnly: false,
	title: 'Error Message',
	messageBody: 'Something went wrong',
}