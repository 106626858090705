/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { UpdateUserAuthDetails, Authentication as AuthFromStore } from '../../../../../app/slices/Authentication'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { IsUserAuthenticated, SaveAuthenticationSession } from '../../../../../utils'
import Icons from '../../redemption-codes/redeem/icons'
import { setCookie } from '../../../../../utils/CookieUtils'
import { deviceType } from '../../../../../helper/constants'
import { useGetJoinGroupInvitationExistingUserQuery, useGetUserLoginMutation } from '../../../../../app/services'
import { useAppSelector } from '../../../../../app/hooks'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { dataCySelector } from '../../../../../app/services/constant'
import { RP_ENV } from '../../../../../configs'
import { reverseObfuscateConstant } from '../../../../../utils/Constant'
import { validateEmail } from '../../redemption-codes/constants'
import ErrorModal from '../../../../../components/error-modal'
import ConfirmJoinModal from './ConfirmJoinModal'

import { ActionsContainer, FieldErrorMessage, FormGroup, FormGroupExistingUser, GroupParentContainer, JoinGroupModalContainer, LaterButton, ProceedButton, TitlePage, ValidatedField } from '../styles'

const groupInitial = {
	formErrors: {
		email: '',
		password: '',
		termsAgreement: '',

	},
	email: '',
	password: '',
	termsAgreement: false,
	token: '',
	invitor: '',
	error: {
		isShow: false,
		message: ''
	},
	lastName: '',
	firstName: ''
}

function JoinGroupExistingUser(): ReactElement {

	const { pathname } = useLocation()

	const dispatch = useDispatch()
	const history = useHistory()

	const [showPassword, setShowPassword] = useState(false)
	const [existUserState, setExistUserState] = useState(groupInitial)
	const [isLoading, setIsLoading] = useState(false)
	const isAuthenticated = IsUserAuthenticated()
	const [errorState, setErrorState] = useState<any>()
	const { User } = useAppSelector(AuthFromStore)
	const [isConfirmModal, setIsConfirmModal] = useState<any>()
	const userId = User?.user?._id

	useEffect(() => {
		fetchUserInvitation()
		return () => {
			setIsLoading(false)
			setExistUserState(groupInitial)
		}
		//eslint-disable-next-line
	}, [])

	const getTokens = () => {
		const token = pathname?.split('/')
		if (token[3]) {
			return token[3]
		}
		return 'null'
	}
	const token = getTokens()
	const [isRunQuery, setIsRunQuery] = useState(true)

	const { data: fetchData, isError: getJoinGroupIsError } = useGetJoinGroupInvitationExistingUserQuery(token, { skip: isRunQuery })

	const fetchUserInvitation = async () => {
		setIsLoading(true)
		try {
			setIsRunQuery(false)
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (fetchData) {
			if (fetchData.success) {
				setExistUserState((prevState: any) => ({
					...prevState,
					token: token,
					firstName: fetchData?.data?.user?.profile?.name?.first_name || '',
					lastName: fetchData?.data?.user?.profile?.name?.last_name || '',
					email: fetchData?.data?.user?.emails[0]?.address || '',
					invitor: fetchData?.data?.manager?.profile?.name?.first_name + ' ' + fetchData?.data?.manager?.profile?.name?.last_name || '',
					country: fetchData?.user?.profile?.address?.country?._id || ''
				}))
			}
			setIsLoading(false)
		}

		if (getJoinGroupIsError) {
			console.log('Something went wrong. Please try again.')
		}
	}, [fetchData, getJoinGroupIsError])


	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setExistUserState((state: any) => ({ ...state, [event.target.id]: event.target.value }))
	}

	const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		const targetId = event.target.id
		let errors = { ...existUserState.formErrors }
		if (targetId === 'termsAgreement') {
			errors = { ...errors, [targetId]: existUserState[targetId] !== true ? 'Make sure that you agree to the terms before you can proceed' : '' }
		} else if (targetId === 'email') {
			errors = { ...errors, [targetId]: (existUserState[targetId] || validateEmail(existUserState[targetId])) ? '' : 'Please enter a valid email address' }
		} else if (targetId === 'password') {
			errors = { ...errors, [targetId]: existUserState[targetId]?.trim() ? '' : 'Password is required' }
		}

		setExistUserState((state: any) => ({ ...state, formErrors: errors }))
	}

	const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setExistUserState((state: any) => ({ ...state, [event.target.id]: event.target.checked }))
	}

	const verifyErrors = () => {
		let errors = { ...existUserState.formErrors }
		errors = { ...errors, ['email']: (existUserState['email'] && validateEmail(existUserState['email'])) ? '' : 'Please enter a valid email address' }
		errors = { ...errors, ['password']: existUserState['password']?.trim() ? '' : 'Password is required' }
		errors = { ...errors, ['termsAgreement']: !existUserState['termsAgreement'] ? 'Make sure that you agree to the terms before you can proceed' : '' }

		setExistUserState((state: any) => ({ ...state, formErrors: errors }))
		return getErrors(errors)
	}

	const errorDisplay = (errorMessage: string) => {
		return <>
			{
				errorMessage && <div className='error-message'>
					<img src={'img/icons/icon_error_warning.svg'} />
					<span>{errorMessage}</span>
				</div>
			}
		</>
	}

	const getErrors = (formErrors: any) => {
		let error = ''
		for (const i in formErrors) {
			error += formErrors[i]
		}
		return error
	}

	const scrollToError = () => {
		if (!existUserState['termsAgreement']) {
			const errorElement = document.querySelector('#terms-agreement-list')
			errorElement?.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const finalErrorValidation = (errorMessages: any, errors: any) => {
		errors = { ...errors, email: errorMessages.email ? errorMessages.email : '' }
		errors = { ...errors, password: errorMessages.password ? errorMessages.password : '' }
		return errors
	}

	const [userLoginMutation, { data: loginUserData, isSuccess: isLoginUserSuccess }] = useGetUserLoginMutation()

	const loginUser = async () => {
		try {
			setIsLoading(true)

			const csrf_token = await getCSURFToken()
			const loginPayload = {
				username: existUserState.email,
				password: existUserState.password,
				device: deviceType()
			}
			const login = await userLoginMutation({ params: loginPayload, token: csrf_token }).unwrap()
			if (!login?.success) {
				setErrorState((state: any) => ({ ...state, isShow: true, message: 'Error: ' + login?.message || 'Login failed. Please try again.' }))
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (loginUserData && isLoginUserSuccess) {
			if (loginUserData && loginUserData.success) {
				SaveAuthenticationSession(
					'user',
					loginUserData.data.accessToken,
					loginUserData.data.accessTokenExpiresAt,
					loginUserData.data.refreshToken,
					loginUserData.data.refreshTokenExpiresAt,
					loginUserData.data.client._id,
					loginUserData.data.user._id
				)
				dispatch(UpdateUserAuthDetails({
					token: loginUserData.data.accessToken,
					tokenExpiry: loginUserData.data.accessTokenExpiresAt,
					refreshToken: loginUserData.data.refreshToken,
					refreshTokenExpiry: loginUserData.data.refreshTokenExpiresAt,
					clientId: loginUserData.data.client._id,
					country: loginUserData.data.country,
					userId: loginUserData.data.user._id
				}))
				setCookie('username', existUserState.email, true)
				history.push({
					pathname: '/join-group-success',
					state: { manager: existUserState.invitor } 
				})
			}
			setIsLoading(false)
		}
	}, [loginUserData, isLoginUserSuccess])

	const joinGroup = async () => {
		setIsLoading(true)
		try {
			const csrf_token = await getCSURFToken()
			const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user-group/join-group-existing`
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + User.accessToken,
					'x-api-key': RP_ENV.API_KEY,
					'XSRF-TOKEN': csrf_token
				},
				credentials: 'include',
				mode: 'cors',
				body: JSON.stringify({
					token: existUserState.token,
					id: userId 
				})
			})
			const result = await response.json()
			if (!result.success) {
				let errors = { ...existUserState.formErrors }
				errors = finalErrorValidation(result.message, errors)
				setExistUserState((state: any) => ({ ...state, formErrors: errors }))
				setErrorState((state: any) => ({ ...state, isShow: true, message: result?.message?.text || 'Transaction Failed. Please try again.' }))
			} else {
				loginUser()
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error)
		}
		return
	}

	const handleJoinGroupExistUser = async () => {
		setIsLoading(true)
		try {
			const csrf_token = await getCSURFToken()
			const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user-group/join-group-existing`
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + User.accessToken,
					'x-api-key': RP_ENV.API_KEY,
					'XSRF-TOKEN': csrf_token
				},
				credentials: 'include',
				mode: 'cors',
				body: JSON.stringify({
					token: existUserState.token,
					id: userId
				})
			})
			const result = await response.json()
			if (!result.success) {
				let errors = { ...existUserState.formErrors }
				errors = finalErrorValidation(result.message, errors)
				setExistUserState((state: any) => ({ ...state, formErrors: errors }))
				setErrorState((state: any) => ({ ...state, isShow: true, message: result?.message?.text || 'Transaction Failed. Please try again.' }))
			} else {
				history.push({
					pathname: '/join-group-success',
					state: { manager: existUserState.invitor } 
				})
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error)
		}
		return
	}

	const handleConfirmJoin = () => {
		if (!isAuthenticated) {
			if (!verifyErrors()) {
				setIsConfirmModal(true)
			} else {
				scrollToError()
			}
		} else {
			setIsConfirmModal(true)
		}
	}

	const handleCloseJoinModal = () => {
		setIsConfirmModal(false)
	}

	const handleProceedJoinModal = async() => {
		joinGroup()
	}

	const handleJoinGroupLater = async () => {
		window.location.href = '/'
	}

	const closeModalError = () => {
		setErrorState((state: any) => ({ ...state, isShow: false, message: '' }))
	}

	return <div className="single-entry">
		<div className="container" >
			{isLoading && <LoadingOverlay />}
			<GroupParentContainer>
				<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap m-b-lg" role="main" >
					<article className="target-container mnmd-block post post--single type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5 margin-bottom-0px" >
						<TitlePage>
							<p className='main-title'>Join Group</p>
							{/* <p className='sub-title'>Please create an account by filling in the form below to join <b>{existUserState.invitor}</b>`s group. Please note that any individual subscriptions this account has will be canceled immediately if you choose to join the group.</p> */}
						</TitlePage>
						{!isAuthenticated && <>
							<FormGroup>
								<p className='form-title'>Login</p>
								<div id='email-block'>
									{/* <label htmlFor='email'>Email Address</label> */}
									<ValidatedField type='email' name='email' id='email' placeholder='Email' value={existUserState.email} onChange={handleChange} onBlur={handleBlur} className={existUserState.formErrors.email ? 'error' : ''} required {...dataCySelector('email-input')} />
									<FieldErrorMessage>{errorDisplay(existUserState.formErrors.email)}</FieldErrorMessage>
								</div>

								<div id='password-block'>
									{/* <label htmlFor='password'>Password</label> */}
									<div className='password-container'>
										<ValidatedField type={showPassword ? 'text' : 'password'} placeholder='Password' name='password' id='password' value={existUserState.password} onChange={handleChange} onBlur={handleBlur} className={existUserState.formErrors.password ? 'error' : ''} required {...dataCySelector('password-input')} />
										{showPassword ? <Icons.InvincibleIcon height='32px' width='32px' onClick={() => setShowPassword(false)} /> : <Icons.VisibleIcon height='32px' width='32px' onClick={() => setShowPassword(true)} />}
									</div>
									<FieldErrorMessage>{errorDisplay(existUserState.formErrors.password)}</FieldErrorMessage>
								</div>

								<ActionsContainer>
									<div className='checkbox-field'>
										<input type='checkbox' checked={existUserState.termsAgreement} id='termsAgreement' onChange={handleCheckChange} onBlur={handleBlur} {...dataCySelector('terms-agreement-checkbox')} />&ensp;
										<span className='agreement'>I have read and agreed to the terms of the <Link to={'/privacy-notice'}>Privacy Notice</Link> and the <Link to={'/subscription-agreement'}>Personal Subscription Agreement</Link></span>
									</div>
									<FieldErrorMessage>{errorDisplay(existUserState.formErrors.termsAgreement)}</FieldErrorMessage>
									<br />
									<div className='activation-buttons'>
										{/* <ProceedButton onClick={handleJoinGroupExistUser} {...dataCySelector('join-notauth-btn')}>JOIN GROUP</ProceedButton> */}
										<ProceedButton onClick={handleConfirmJoin} {...dataCySelector('join-notauth-btn')}>JOIN GROUP</ProceedButton>
									</div>
								</ActionsContainer>
							</FormGroup>
							<br />

						</>}
						{isAuthenticated &&
							<ActionsContainer className='authenticated-actions'>
								<FormGroupExistingUser>
									<div className='header'>You have been invited to join {existUserState.invitor}`s group.</div>
									<div className='value'>If you have any current subscriptions, they will be cancelled in favour of the managers Commercial subscription.</div>
									<div className='activation-buttons-login'>
										<LaterButton onClick={handleJoinGroupLater} > MAYBE LATER</LaterButton>
										<ProceedButton onClick={handleJoinGroupExistUser} {...dataCySelector('join-auth-btn')}>JOIN GROUP</ProceedButton>
									</div>
								</FormGroupExistingUser>
							</ActionsContainer>}
					</article>
				</div>
			</GroupParentContainer>
		</div>

		{errorState?.isShow &&
			<ErrorModal messageBody={errorState?.message} isOpen={errorState?.isShow} onClose={closeModalError} />
		}

		{isConfirmModal &&
			<ConfirmJoinModal
				isOpen={isConfirmModal}
				onClose={handleCloseJoinModal}
				onProceed={handleProceedJoinModal}
				title='Confirm'
			>
				<JoinGroupModalContainer>
					<div className='header'>You have been invited to join {existUserState.invitor}`s group.</div>
					<div className='value'>If you have any current subscriptions, they will be cancelled in favour of the managers Commercial subscription.</div>
				</JoinGroupModalContainer>
			</ConfirmJoinModal>
		}
	</div>
}

export default JoinGroupExistingUser
