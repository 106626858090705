/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useRef, useState } from 'react'
import { useForm, FieldValues, RegisterOptions } from 'react-hook-form'
import { isMobile } from 'react-device-detect'
import { CloseButton, ContentField, ModalBody, ModalContent, ModalHeader, ModalUpgradeFooter, ModalWrapper, Separator } from './styles'
import Checkbox from '../../../../../../components/form-components/checkbox/Checkbox'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { reverseObfuscateConstant } from '../../../../../../utils/Constant'
import { RP_ENV } from '../../../../../../configs'
import { useFreeExpiredSubscriptionMutation } from '../../../../../../app/services/Subscription'
import { getCSURFToken } from '../../../../../../utils/CSRFToken'
import LoadingOverlay from '../../../../../../components/Loading-overlay'
import NoticeModal from '../../../../../../components/notice-modal'

type ModalType = {
	isOpen: boolean
	onClose: (isSuccess?: boolean, errorMessage?: string) => void;
	title?: string;
	width?: number;
	planId?: string;
	customerId?: string;
}

const aggrementCheckboxRules: RegisterOptions = {
	required: 'Please check agreement to proceed.',
	validate: (value) => value !== false || 'Please check the agreement'
}

const plans = [
	{
		label: 'Personal quarterly',
		plan: 'Personal Quarterly',
		price: 37.00,
		planId: 'personal-3-month',
	},
	{
		label: 'Personal annual',
		plan: 'Personal Annual',
		price: 129.00,
		planId: 'personal-1-year',
	},
	{
		label: 'Personal monthly',
		plan: 'Personal Monthly',
		price: 15.00,
		planId: 'personal-monthly',
	},
	{
		label: 'Commercial annual',
		plan: 'Commercial Annual',
		price: 249.00,
		planId: 'commercial-1-year',
	}
]

const NewSubscription = ({ isOpen, onClose, planId, customerId }: ModalType): ReactElement => {
	const { control, handleSubmit, setError, setValue, clearErrors, formState: { errors } } = useForm<FieldValues>()

	const [ executeFreeExpiredSubscriptionMutation, { data: renewalData, isLoading: renewalIsLoading, isError: renewalIsError }] = useFreeExpiredSubscriptionMutation()

	const recaptchaRef = useRef<any>(null)
	const [noticeModal, setNoticeModal] = useState(false)
	const [modalMessage, setModalMessage] = useState('')
	const aggreementText = planId?.includes('personal') ? 'Personal' : 'Commercial'

	const price = plans.find(plan => plan.planId === planId)?.price
	const label = plans.find(plan => plan.planId === planId)?.label
	const plan = plans.find(plan => plan.planId === planId)?.plan

	const handleClose = () => {
		onClose()
	}

	const onSubmit = async (formData: any) => {
		if (!formData.recaptcha) {
			return setError('recaptcha', {
				type: 'custom',
				message: 'Recaptcha token is required.',
			})
		}
	
		const payload = {
			customerId: customerId,
			recaptcha_token: formData?.recaptcha,
		}
	
		try {
			const csrf_token = await getCSURFToken()
			const renewal = await executeFreeExpiredSubscriptionMutation({ params: payload, token: csrf_token }).unwrap()
	
			if (renewal?.success) {
				onClose(true, '')
			} else {
				setNoticeModal(true)
	
				const modalMessage = typeof renewal?.message === 'string' 
					? renewal.message 
					: renewal?.message?.text || 'An unexpected error occurred. Please try again later.'
				setModalMessage(modalMessage)
			}
		} catch (error) {
			setNoticeModal(true)
			setModalMessage('An unexpected error occurred. Please try again later.')
		}
	}

	const handleRecaptchaChange = (token: string | null) => {
		if (token) {
			clearErrors('recaptcha')
		}
		setValue('recaptcha', token)
	}

	return (
		<ModalWrapper isMobile={isMobile} isOpen={isOpen} className='modal'>
			{renewalIsLoading && <LoadingOverlay />}
			<ModalContent isMobile={isMobile}>
				<ModalHeader>
					<CloseButton onClick={handleClose}>&#10005;</CloseButton>
				</ModalHeader>
				<form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '92.5%', justifyContent: 'space-between' }}>
					<ModalBody>
						<div className='header'>Buy subscription</div>
						<div className='sub-header margin-32px-32px'>Your {label} subscription is expired. Do you want to renew your subscription right now?</div>
						<div className='header'>Order Review</div>
						<div className='sub-header margin-16px'>You will be paying for the following products</div>
						<ContentField>
							<div className='planId'>{plan} (Auto-Renew)</div>
							<div className='planPrice'>${price}</div>
						</ContentField>
						<Separator />
						<ContentField>
							<div className='totalLabel'>Total</div>
							<div className='planPrice'>${price}</div>
						</ContentField>
						<Checkbox name='isAggrement' control={control} rules={aggrementCheckboxRules}>
							<span style={{ width: '100%', fontSize: '12px', lineHeight: '1.25' }}>
								By checking this box and subscribing, I agree to the Robert Parker Wine Advocate <Link target='_blank' to={`/subscription-agreement?selectedPlan=${aggreementText === 'Personal' ? 'personal' : 'commercial'}`} className="bold-link">{` ${aggreementText}`} Subscription Agreement</Link>.
								I understand that I am requesting immediate access to digital content and therefore waive my right to withdraw from the subscription.<br />
							</span>
						</Checkbox>
						<div style={{ display: 'flex', flexDirection:'column', justifyContent: 'center', margin: '14px 0 24px 0', alignItems:'center' }}>
							<i>Please check the box below to proceed.</i>
							<ReCAPTCHA
								sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
								onChange={handleRecaptchaChange}
								ref={recaptchaRef}
							/>
							{errors.recaptcha && (
								<div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
									{errors.recaptcha.message}
								</div>
							)}
						</div>
						<ModalUpgradeFooter>
							<button className='cancel' type='button' onClick={handleClose}>CANCEL</button>
							<button className='renew' type='submit'>RENEW SUBSCRIPTION</button>
						</ModalUpgradeFooter>
					</ModalBody>
				</form>
			</ModalContent>
			{noticeModal && 
				<NoticeModal messageBody={modalMessage} title={'CHECKOUT ERROR'} isOpen={noticeModal} isBlocked={true} isPaymentUpdate={false}/>
			}
		</ModalWrapper>
	)
}

export default NewSubscription
