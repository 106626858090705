/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactElement} from 'react'
import { Link } from 'react-router-dom'



// i18n
import { TranslationsUtil } from '../../../../utils'
import englishTranslation from './translations/en.json'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
// import useArticleTracking from '../../../../helper/hooks/useArticleTracking'
const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

function FeaturedArticleMongo(props: { article: any }): ReactElement {

	const { article } = props
	const translate = translationUtil.getTranslator()
	// const { triggerTrackingHandler } = useArticleTracking()

	return (
		<article className="post--overlay post--overlay-bottom post--overlay-sm post--overlay-floorfade cat-4">
			<div className="background-img"><CloudImageUtils imgSrc={article.banner?.url} alt="image" ratio={1.5} params=''/></div>
			<div className="post__text inverse-text">
				<div className="post__text-wrap">
					<div className="post__text-inner">
						<h6 className="post__title typescale-1">{ article?.title }</h6>
						<div className="post__excerpt text-white">
							{ article?.excerpt }
						</div>
						<div className="post__meta post__meta--flex post__meta--border-top">
							<div className="post__meta-left">
								<span className="entry-author text-white">{ translate('By') }&nbsp;
									<Link to={ `/author/${ article?.author?.id }` } className="entry-author__name" >{ article?.author?.name }</Link>
								</span>
							</div>
							{/* <div className="post__meta-right">
								<time className="time published" dateTime={ article.published_date } title={ article.getPublishedDate('title') }><i className="mdicon mdicon-schedule"></i>{ article.getPublishedDate('display') }</time>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<Link to={ `/articles/${ article?.id }`} className="link-overlay"></Link>
			{/* <Link to={ '/article/categories' } className="post__cat post__cat--bg cat-theme-bg overlay-item--top-left">{ article.category.name }</Link> */}
			{/* <span className="post__cat post__cat--bg cat-theme-bg overlay-item--top-left">{ article.category.name }</span> */}
		</article>
	)
}

export default FeaturedArticleMongo