function addHttps(url: string) {
	if (!url.startsWith('http://') && !url.startsWith('https://')) 
		return 'https://' + url
	return url
}

export const validateURL = (url: string): boolean => {
	const finalUrl = addHttps(url)
	try {
		const parsed = new URL(finalUrl)
		
		return ['https:', 'http:'].includes(parsed.protocol)
	} catch (error) {
		return false
	}
}