import styled from 'styled-components'

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
`
export const PageTitle = styled.h3`
	font-size: 40px;
	font-weight: bold;
	color: #333;
`

export const PreviewContianer = styled.div`
	display: flex;
	cursor: pointer;

	& div.featured {
		margin-right: 30px;
		width: 560px;
		height: 350px;
		mix-blend-mode: multiply;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: end;

		& div.featured-title {
			width: 520px;
			font-size: 21px;
			letter-spacing: normal;
			color: #fff;
			${commonTextStyle};
		}

		& div.featured-summary {
			color: #fff;
		}

		& div.fetaured-details {
			color: #fff;
			display: flex;
			justify-content: space-between;
		}
	}

	& div.article-preview {
		width: 280px;
		height: 350px;
		border: solid 1px #ddd;
		background-color: #fff;
		margin-right: 20px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		& div.image-container {
			width: 280px;
			margin-bottom: 30px;

			& Img {
				height: 140px;
			}

			& div.preview-tag {
				width: 122px;
				height: 18px;
				background-color: #194fb0;
				text-align: center;
				color: #fff;
				margin: -10px auto;
				left: 0;
				right: 0;
				z-index: 100;
				position: relative;
			}
		}

		& div.title-container {
			width: 240px;
			height: fit-content;
			margin: 10px 20px 10px 20px;
			opacity: 0.77;
			font-size: 18px;
			font-weight: normal;
			text-align: center;
			color: #777;
			${commonTextStyle}
		}

		& div.excerpt-container {
			margin: 0 18px;
			color: #777;
			${commonTextStyle}

			padding-bottom: 5px;
			max-height: 120px;
			overflow: hidden;
			text-overflow: ellipsis;
			content: '';
			position: relative;
		}

		& div.excerpt-container:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: linear-gradient(transparent 20px, white);
		}

		& div.details-container {
			display: float;
			justify-content: space-between;
			margin: 0px 18px 5px 18px;

			& div {
				font-size: 12px;
				font-weight: normal;
				color: #777;
				${commonTextStyle}
			}
		}
	}
`

export const PreviewContianerMobile = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;

	& div.featured {
		@media only screen and (max-width: 580px) {	
			width: 80%;
		}
		@media only screen and (max-width: 500px) {	
			width: 70%;
		}
		@media only screen and (max-width: 430px) {	
			width: 60%;
		}
		@media only screen and (max-width: 367px) {	
			width: 52%;
		}
		@media only screen and (max-width: 320px) {	
			width: 50%;
		}
		margin: 10px 30px 10px;
		width: 90%;
		height: 350px;
		mix-blend-mode: multiply;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: end;

		& div.featured-title {
			width: 520px;
			font-size: 21px;
			letter-spacing: normal;
			color: #fff;
			${commonTextStyle};
		}

		& div.featured-summary {
			color: #fff;
		}

		& div.fetaured-details {
			color: #fff;
			display: flex;
			justify-content: space-between;
		}
	}

	& div.article-preview {
		@media only screen and (max-width: 580px) {	
			width: 80%;
		}
		@media only screen and (max-width: 500px) {	
			width: 70%;
		}
		@media only screen and (max-width: 430px) {	
			width: 60%;
		}
		@media only screen and (max-width: 367px) {	
			width: 52%;
		}
		@media only screen and (max-width: 320px) {	
			width: 50%;
		}
		width: 90%;
		height: 350px;
		border: solid 1px #ddd;
		background-color: #fff;
		margin: 10px 15px 10px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		& div.image-container {
			width: fit-content;
			margin-bottom: 30px;

			& Img {
				width: 800px;
				height: 140px;
			}

			& div.preview-tag {
				width: 122px;
				height: 18px;
				background-color: #194fb0;
				text-align: center;
				color: #fff;
				margin: -10px auto;
				left: 0;
				right: 0;
				z-index: 100;
				position: relative;
			}
		}

		& div.title-container {
			width: 240px;
			height: fit-content;
			margin: 10px 20px 10px 20px;
			opacity: 0.77;
			font-size: 18px;
			font-weight: normal;
			text-align: center;
			color: #777;
			${commonTextStyle}
		}

		& div.excerpt-container {
			margin: 0 18px;
			color: #777;
			${commonTextStyle}

			padding-bottom: 5px;
			max-height: 120px;
			overflow: hidden;
			text-overflow: ellipsis;
			content: '';
			position: relative;
		}

		& div.excerpt-container:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: linear-gradient(transparent 20px, white);
		}

		& div.details-container {
			display: float;
			justify-content: space-between;
			margin: 0px 18px 5px 18px;

			& div {
				font-size: 12px;
				font-weight: normal;
				color: #777;
				${commonTextStyle}
			}
		}
	}
`

export const FeatureAricleContianer = styled.div<{ bgImage: string }>`
	background-image: url(${(props) => (props.bgImage ? props.bgImage : '')});
	background-size: cover;
`

export const BoxContent = styled.div<{ isCentered: boolean }>`
	margin-bottom: 20px;
	display: flex;
	${(props) => (props.isCentered ? 'justify-content: center' : '')};
`

export const BoxContentMobile = styled.div<{ isCentered: boolean }>`
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	${(props) => (props.isCentered ? 'justify-content: center' : '')};
`

export const ContributorsLink = styled.div`
	width: 330px;
	height: 255px;
	margin: 20px 138px 40px 68px;
	padding: 40px;
	border: solid 4px #e5e5e5;
	background-color: #fff;
`

export const ContributorsLinkMobile = styled.div`
	width: 100%;
	height: auto;
	padding: 40px;
	border: solid 4px #e5e5e5;
	background-color: #fff;
`

export const IntroParagraph = styled.div`
	max-width: 769px;

	& p {
		font-size: 24px;
		margin-top: 10px;
	}
`

export const IntroParagraphMobile = styled.div`
	max-width: 769px;

	& p {
		font-size: 4vw;
		margin-top: 10px;
	}
`

export const OrangeButton = styled.div`
	height: 33px;
	line-height: 33px;
	border-radius: 8px;
	background-color: #73333f;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	text-align: center;

	&:hover { 
		background-color: #8E4250;
	}
`

export const SectionTitle = styled.div`
	margin-top: 50px;
	margin-bottom: 20px;
`

export const TitleContainer = styled.span<{ isBold: boolean }>`
	font-size: 20px;
	font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
`
//new style 
export const PageHeader = styled.div`
	background-color: #fafafa;
	margin: -60px 0px 40px 0px;
	padding: 40px 0px 40px 0px;

	& div.title {
		margin: 10px 0 0 10px;

		font-size: 24px;
		font-weight: bold;
		${commonTextStyle}
		color: #000000;
	}

	& div.summary {
		margin: 10px 0 0 10px;
		font-size: 14px;
		font-weight: normal;
		${commonTextStyle}
		color: #777;
	}
`

export const ResultContainer = styled.div`
	display: flex;
	width: 79%;
	margin-left: 30px;
`

export const ListAndFilters = styled.div`
	width: 100%;
	& div {
		margin-bottom: 10px;
	}
`

export const FiltersContainer = styled.div`
	display: flex;

	& div.filter-options {
		display: flex;
		margin-bottom: 30px;

		& label {
			margin-top: 5px;
			margin-right: 10px;
			height: 16px;
			font-size: 14px;
			font-weight: bold;
			${commonTextStyle}
		}

		& select {
			width: 263px;
			height: 33px;
			padding: 6px 12px;
			border-radius: 8px;
			border: solid 1px #ccc;
			margin-right: 20px;
		}
	}
`

export const ArticleContainer = styled.div`
	width: auto;
	display: flex;
	margin-bottom: 25px;
	margin-left: auto;
	margin-right: auto;
	/* cursor: pointer; */

	& div.background-img {
		border: 1px solid red;
		height: 195px;
		width: 200px;
	}

	& div.details-container {
		width: 100%;
		padding: 10px 0px 0px 0px;

		& div.title {
			margin: 0 0 10px 0px;
			font-size: 21px;
			font-weight: bold;
			${commonTextStyle}
			color: var(--black-two);
		}

		& .linktitle {
			margin: 0 0 10px 0px;
			font-size: 21px;
			font-weight: bold;
			${commonTextStyle}
			color: var(--black-two);
			text-decoration: none;
		}

		& div.excerpt {
			margin: 10px 0 8px 0px;
			font-size: 14px;
			font-weight: normal;
			${commonTextStyle}
			height: 61px;
			color: #777;
			overflow: hidden;
			-webkit-line-clamp: 3;
			text-overflow: ellipsis;
		}
		& div.filter-category { 
			height: 24px;
			margin: 0px 0 10px 0px;
			font-family: Libre Franklin;
			font-size: 14px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #A08436;
		}

		& a.filter-category-journal {
			color: #A08436 !important;
			font-weight: 700 !important;
			text-decoration: none;

			&:hover{
				color:black !important;
			}
		}

		& div.details {
			color: #777;
			margin-top: 4px;
			width: 100%;
			display: flex;

			& div.author {
				margin-right: 7px;
				& span {
					font-weight: bold;
				}
			}

			& .date {
				font-weight: 100 !important;
			}

			& div.detail-container {
				margin-right: 7px;
				& Img {
					margin-right: 3px;
				}
			}
		}
		& div.tags-style {
			display: flex;
			justify-content: flex-end;
			text-align: right;
			margin-left: auto;
		}

		& div.tagcloud{
			display: flex;
		}

		& div.tags-label {
			margin-top: 6px;
			margin-right: 5px;
		}
	}
	@media (max-width: 768px) {
		flex-direction: column;

		& div.details-container {
			width: 100%;
			margin-top: 20px;
		}

		& div.title {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`

export const ArticleImageContainer = styled.div`
	width: 360px;
	height: 195px;
	margin: 0 30px 0 0;
	mix-blend-mode: multiply;
	background-image: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.5),
		rgba(0, 0, 0, 0.5)
	);

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		overflow: hidden;
		cursor: pointer;
	}
	@media (max-width: 768px) {
		width: auto;
		height: 100%;
		margin: 0;
	}
`

export const FilterContainer = styled.div`
	display: flex;
    align-items: center;
    justify-content: start;
    gap: 10rem;
`