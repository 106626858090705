/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import DOMPurify from 'dompurify'

import { SUSTAINABILITY_PAGES } from '../../../../configs'
import SectionSeparator from '../../sections/section-separator'
import LoadingOverlay from '../../../../components/Loading-overlay'

// Styles 
import {
	AwardContainer,
	Banner,
	CompanyDetailsContainer,
	ContentParagraphs,
	ContactContainer,
	Logo,
	SectionTitle,
	TabbedMenu,
	TabbedMenuItem,
	GreenEmblemHeader,
	GreenEmblemBackButton,
	HeaderImage
} from './styles'
import { SetActiveAdvanceFilters, SetActiveFilters, SetFacetsFilters } from '../../../../app/slices/SearchFilters'
import { filtersFromQueryString } from '../../../../utils/FilterUtils'
import { validateURL } from '../../../../utils/ValidateUrl'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { useGetEcoDetailsByCompanyIdQuery } from '../../../../app/services'
import { addTargetBlank } from '../../../../helper/constants'
import useScreen from '../../../../helper/hooks/useScreen'
import { isMobile } from 'react-device-detect'

function GreenEmblemCompany(): ReactElement {

	const history = useHistory()

	const { companyId } = useParams<{ companyId: string }>()
	const dispatch = useDispatch()

	const { data: company, isLoading } = useGetEcoDetailsByCompanyIdQuery(companyId, {skip: !companyId})

	const [activeMenu, setActiveMenu] = useState(0)

	const execActiveMenu = (index: number) => setActiveMenu(index)

	const winesByProducerHandler = (qString: string) => {
		const queryStrings = queryString.parse(qString) as any
		dispatch(SetFacetsFilters({
			activeFilters: filtersFromQueryString(queryStrings),
		}))
	}

	const screen = useScreen()

	const imageRatio = () => {
		switch (screen) {
		case 'tablet portrait':
			return 2.2
		case 'desktop':
			return 5.5
		case 'smallMobile':
			return 1.2
		default:
			return 1.9
		}
			
	}

	const cleanComment = DOMPurify.sanitize(addTargetBlank(company?.comment), {ADD_ATTR: ['target']})
	const cleanAbout = DOMPurify.sanitize(addTargetBlank(company?.about), {ADD_ATTR: ['target']})

	return (
		<>	{isLoading && <LoadingOverlay />}
			{ company ? (
				(
					<>
						<HeaderImage>
							{company?.banner && company?.banner?.url ? (<Banner>
								<div className='image'><CloudImageUtils imgSrc={company?.banner?.url} alt="image" ratio={imageRatio()} params={`org_if_sml=1&dpr=${imageRatio()}`}/></div>
							</Banner>) : (<></>)}
							<br/>
					
							<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
								<div className="container">
									<GreenEmblemBackButton onClick={()=> history.goBack()}>Back to Green Emblem List</GreenEmblemBackButton>
									<GreenEmblemHeader>
										<h1>{company?.name}</h1> 
										{!isMobile &&
									<AwardContainer>
										<div className={'award_icon'} ><img src={SUSTAINABILITY_PAGES.GREEN_EMBLEM_LOGO} /></div>
										<div className={'award_text'} >AWARDED SINCE {company?.year_awarded}</div>
									</AwardContainer>}
									</GreenEmblemHeader>
								</div>
							</div>
						</HeaderImage>
						<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
							<div className="container">
								<div className="row">
									<CompanyDetailsContainer className="mnmd-sub-col mnmd-sub-col--left sidebar js-sticky-sidebar" role="complementary">
										<div className={'group'} >
											<Logo>{company?.logo ? 
												<>
													<CloudImageUtils imgSrc={company?.logo?.url} alt="image" ratio={1.5} params=''/>
												</>
												: <></>}</Logo>
											
										</div>
										<div className={'group'}>
											<ContactContainer>
												<div className={'icon'} ><img src={'/img/icons/open-in-new.png'} /></div>
												<div className={'phone'} ><a href={validateURL(`${company?.website_url}`) ? `${company?.website_url}` : ''} target='_blank' rel="noreferrer" >{company?.website_url}</a></div>
											</ContactContainer>
										</div>
										<div className={'group'} >
											<TabbedMenu>
												<TabbedMenuItem isActive={activeMenu === 0} onClick={() => { execActiveMenu(0) }} >
													<div className={'bar'} ></div>
													<div className={'menu'} >About the producer</div>
												</TabbedMenuItem>
												<TabbedMenuItem isActive={activeMenu === 1} onClick={() => {
													execActiveMenu(1)
													winesByProducerHandler(`?&producer=${encodeURIComponent(company?.name)}`)
													history.push(`/search/wine?&producer=${encodeURIComponent(company?.name)}`)
													dispatch(SetActiveFilters({}))
													dispatch(SetActiveAdvanceFilters({}))
												}} >
													<div className={'bar'} ></div>
													<div className={'menu'} >Wines</div>
												</TabbedMenuItem>
											</TabbedMenu>
										</div>
									</CompanyDetailsContainer>

									<div className="mnmd-main-col has-left-sidebar" role="main">
										<article className="mnmd-block post post--single post-10 type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5" itemScope itemType="https://schema.org/Article">
											<div className="page-schema-meta">
												<SectionTitle>Reviewers Commentary</SectionTitle>
												{isMobile &&
												<AwardContainer>
													<div className={'award_icon'} ><img src={SUSTAINABILITY_PAGES.GREEN_EMBLEM_LOGO} /></div>
													<div className={'award_text'} >AWARDED SINCE {company?.year_awarded}</div>
												</AwardContainer>}
												<ContentParagraphs>
													<div dangerouslySetInnerHTML={{ __html: cleanComment }}></div>
												</ContentParagraphs>
												<SectionSeparator position={'horizontal'} ></SectionSeparator>
												<SectionTitle>About the Producer</SectionTitle>
												<ContentParagraphs>
													<div dangerouslySetInnerHTML={{ __html: cleanAbout }}></div>
												</ContentParagraphs>
											</div>
										</article>

									</div>
								</div>
							</div>
						</div>
					</>
				)
			) : null}
		</>
	)
}

export default GreenEmblemCompany