/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import ExistingAccount from './existing-account/ExistingAccount'
import NewAccount from './new-account/NewAccount'
import ErrorModal from '../../../../../components/error-modal'
import { useActivateExistingUserGiftSubMutation, useActivateNewUserGiftSubMutation, useGetTradeDirectoryLookupCountriesQuery, useGetUserLoginMutation } from '../../../../../app/services'
import CommonModalContainer from '../../../../modals/ModalContainer'
import LoginForms from '../../../Common/Login/LoginForms'
import { setCookie } from '../../../../../utils/CookieUtils'
import { SaveAuthenticationSession } from '../../../../../utils'
import { UpdateUserAuthDetails } from '../../../../../app/slices/Authentication'
import { deviceType } from '../../../../../helper/constants'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { trimmedEmails } from '../../../../../utils/Constant'

import { GiftSubMainContainer, GiftSubTitle } from '../styles'
import { setAccessLocalStorage } from '../../../../../utils/ValidateAccessToken'

function GiftSubActivation(): ReactElement {

	const [activationError, setActivationError] = useState({
		isError: false,
		message: ''
	})
	const [countries, setCountries] = useState<string[]>([])
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
    

	const { control, handleSubmit, getValues, setValue, setError } = useForm()

	const { userData } = useAppSelector(UsersFromStore)

	const history = useHistory()
	const { token } = useParams<{token: string}>()
	const dispatch = useDispatch()

	const { data: countryData } = useGetTradeDirectoryLookupCountriesQuery('')
	const [activateExistingUserGiftSubMutation, { isLoading: activateExistingLoading }] = useActivateExistingUserGiftSubMutation()
	const [ activateNewUserGiftSubMutation, { isLoading: activateNewLoading } ] = useActivateNewUserGiftSubMutation()
	const [userLoginMutation, { isLoading: userLoginLoading }] = useGetUserLoginMutation()

	const isLogged = userData?._id ? true : false
	const endMoment = moment(userData?.subscription?.end).add(1, 'year')
	const endDateFormatted = endMoment.format('MMMM DD, YYYY')
	const recaptchaRef = useRef(null)

	useEffect(() => {
		if (countryData?.success) {
			const countryList = countryData.data.map((country: { _id: string; code: string; name: string; }) => country.name)
			setCountries(countryList)
		}
	}, [countryData])

	const loginUser = async () => {
		try{
			const csrf_token = await getCSURFToken()

			const loginPayload = {
				username: getValues('email'),
				password: getValues('password'),
				device: deviceType(),
			}
			const loginResultData = await userLoginMutation({params: loginPayload, token: csrf_token}).unwrap()

			if(!loginResultData.success)
				return setActivationError({
					isError: true,
					message: loginResultData.message || ''
				})
			
			SaveAuthenticationSession(
				'user',
				loginResultData.data.accessToken,
				loginResultData.data.accessTokenExpiresAt,
				loginResultData.data.refreshToken,
				loginResultData.data.refreshTokenExpiresAt,
				loginResultData.data.client._id,
				loginResultData.data.user._id
			)

			setAccessLocalStorage(loginResultData.data.accessToken, loginResultData.data.accessTokenExpiresAt, loginResultData.data.refreshToken, loginResultData.data.refreshTokenExpiresAt)

			dispatch(UpdateUserAuthDetails({
				token: loginResultData.data.accessToken,
				tokenExpiry: loginResultData.data.accessTokenExpiresAt,
				refreshToken: loginResultData.data.refreshToken,
				refreshTokenExpiry: loginResultData.data.refreshTokenExpiresAt,
				clientId: loginResultData.data.client._id,
				country: loginResultData.data.country,
				userId: loginResultData.data.user._id
			}))

			setCookie('username', getValues('email'), true)

			history.push('/gift-subscription/activate-success?type=new')
			return window.location.reload()

		} catch(error) {
			console.log(error)
		}
	}

	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
		}
	}

	const activateGiftExistingAccount = async () => {

		const payload = {
			token,
			_id: userData?._id
		}

		try {
			const csrf_token = await getCSURFToken()

			const response = await activateExistingUserGiftSubMutation({params: payload, token: csrf_token}).unwrap()

			if(!response.success)
				return setActivationError({
					isError: true,
					message: response.message || ''
				})
				
			history.push('/gift-subscription/activate-success?type=exist')
			return window.location.reload()

		} catch (error: any) {
			return setActivationError({
				isError: true,
				message: error.message || ''
			})
		}
	}

	const activateGiftNewAccount = async (formData: FieldValues) => {
		if (!formData.recaptcha)
			return setError('isAggrement', {
				type: ' custom',
				message: 'Recaptcha token is required.'
			})

		const countrySelected = countryData.data.find((country: any) => country.name === formData.country)

		const payload = {
			token,
			title: '',
			username: trimmedEmails(formData.email),
			password: formData.password,
			firstName: formData.firstName,
			lastName: formData.lastName,
			receive_newsletter: formData.isNewsLetter,
			receive_marketingletter: formData.isMarketing,
			country: countrySelected,
			planId: 'personal-1-year',
			fax: '',
			website: '',
			card: {
				cvv: '',
				expirationDate: '',
				paymentMethodToken:'',
				cardName: '',
				zip_code:'',
				cardNumber: ''
			},
			recaptcha_token: formData.recaptcha
		}

		try {
			const csrf_token = await getCSURFToken()

			const response = await activateNewUserGiftSubMutation({ params: payload, token: csrf_token }).unwrap()

			if (!response.success) {
				handleRecaptchaReset()
				if (response.message === 'Your email address is not the same as the invited email.')
					return setError('email', {
						type: 'custom',
						message: 'Your provided email address does not correspond with the invited email.'
					})
				return setActivationError({
					isError: true,
					message: response.message
				})
			}

			handleRecaptchaReset()
			
			await loginUser()

		} catch (error: any) {
			return setActivationError({
				isError: true,
				message: error.message || ''
			})
		}
	}
    
	return <>
		{userLoginLoading && <LoadingOverlay />}
		{activateExistingLoading && <LoadingOverlay />}
		{activateNewLoading && <LoadingOverlay />}
		<div className="single-entry">
			<div className="container" style={{ padding: isMobile ? 0 : 15 }}>
				<GiftSubMainContainer isMobile={isMobile}>
					<GiftSubTitle isMobile={isMobile}>
						<p>Activate subscription</p>
					</GiftSubTitle>
					<div className='gift-grid-container'>

						{ isLogged ? <ExistingAccount endDate={endDateFormatted} onActivateGiftExistingAccount={activateGiftExistingAccount} onCancelActivation={() => history.push('/')} /> : <NewAccount countries={countries} control={control} handleSubmit={handleSubmit} getValues={getValues} onSubmit={activateGiftNewAccount} setValue={setValue} recaptchaRef={recaptchaRef} openLoginModal={() => setIsLoginModalOpen(true)} /> }
					</div>
				</GiftSubMainContainer>
			</div>
		</div>
		<CommonModalContainer isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}>
			<LoginForms setIsOpen={setIsLoginModalOpen} />
		</CommonModalContainer>
		{ activationError.isError && <ErrorModal messageBody={activationError.message} isOpen={activationError.isError} onClose={() => setActivationError({ isError: false, message: '' })} bgColor='#73333F' closeTextColor='white' /> }
	</>
}

export default GiftSubActivation
