import React, { ReactElement, useState, ChangeEvent } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import {
	PageTitle,
	QAContainer,
	QuestionTitle,
	Question,
	QuestionAnswer,
	QuestionContainer,
	AnswerContainer,
	TextStyle3,
	TextStyle1,
	Row,
	UL50PX,
	CustomUL,
	AnswerContainerMobile,
} from './common'
import { isMobile } from 'react-device-detect'
import { sanitizeUrl } from '@braintree/sanitize-url'

function SiteContentAndFeatures(): ReactElement {

	const [selectedOption, setSelectedOption] = useState('')

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = event.target.value
		setSelectedOption(selectedValue)

		// Check the selected value and navigate to the target ID
		if (selectedValue === 'question1') {
			window.location.href = 'faq/#rp-information'
		} else if (selectedValue === 'question2') {
			window.location.href = 'faq/#print-information'
		} else if (selectedValue === 'question3') {
			window.location.href = 'faq/#print-shelf-takers'
		} else if (selectedValue === 'question4') {
			window.location.href = 'faq/#print-selected-reviews'
		} else if (selectedValue === 'question5') {
			window.location.href = 'faq/#store-wine-cellar-info'
		} else if (selectedValue === 'question6') {
			window.location.href = 'faq/#access-cellar'
		} else if (selectedValue === 'question7') {
			window.location.href = 'faq/#enter-wine'
		} else if (selectedValue === 'question8') {
			window.location.href = 'faq/#subscriber-visibility'
		} else if (selectedValue === 'question9') {
			window.location.href = 'faq/#chart-rating'
		} else if (selectedValue === 'question10') {
			window.location.href = 'faq/maturity-classifcation'
		} else if (selectedValue === 'question11') {
			window.location.href = 'faq/#next-issue-coverage'
		} else if (selectedValue === 'question12') {
			window.location.href = 'faq/#incorrect-information'
		}
		// Add more conditions as needed
	}

	return (
		<div role="main">
			{isMobile ?
				<>
					<PageTitle>Site Content {'&'} Features</PageTitle>
					<QAContainer>

						<span className='jump-question'>Jump to question</span>
						<select value={selectedOption} onChange={(e) => handleChange(e)} className='jump-question-select component-design'>
							<option value=''>- Select a question -</option>
							<option value='question1'>What information is available on RobertParker.com?</option>
							<option value='question2'>Can I print the information?</option>
							<option value='question3'>I am a Commercial Subscriber. How do I print shelf talkers?</option>
							<option value='question5'>Can I store my wine cellar information on the web site?</option>
							<option value='question7'>Can I enter any wine I want in RP Cellar?</option>
							<option value='question8'>Will the information I enter be visible to other subscribers?</option>
							<option value='question9'>How are the wines in the vintage chart rated?</option>
							<option value='question10'>What do the maturity classifications mean?</option>
							<option value='question11'>What wines will be covered in the next issue of The Wine Advocate?</option>
							<option value='question12'>There is incorrect and/or missing information in the website.</option>
						</select>
						<Row>
							<AnswerContainerMobile>
								<div id='#rp-information'></div>
								<QuestionTitle>
									What information is available on RobertParker.com?
									<QuestionAnswer>
										<CustomUL>
											<li>
												Virtually all tasting notes from <i>The Wine Advocate</i> dating back to 1992, plus hundreds of “overflow” tastings that the printed edition could not accommodate. There are tasting notes for over 450,000 wines on the site today, and we publish weekly.
											</li>
											<li>
												Most of the articles from <i>The Wine Advocate</i>, starting from the 1995 issues plus articles from Robert Parker’s books and other sources.
											</li>
											<li>
												<TextStyle3><Link to="/free-publications/wine-journal">Wine Journal:</Link></TextStyle3> <i>The Wine Advocate</i> reviewers publish stories detailing their outlandish gustatory experiences along with informal tasting notes not published elsewhere.
											</li>
											<li>
												Wine-Searcher prices: Through our partnership with Wine-Searcher, we offer single-click access to current retail offerings already indexed to our tens of thousands of wines to assist you in finally landing some of those hard-to-find wines you’ve always wanted.
											</li>
										</CustomUL>

									</QuestionAnswer>
								</QuestionTitle>
								<div id='print-shelf-takers'></div>
								<QuestionTitle>
									Can I print the information?
									<QuestionAnswer>
										Personal subscribers can print single tasting notes. Commercial subscribers have the option to print shelf talkers in different formats that are most useful to present a wine with its score and tasting note.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I am a Commercial Subscriber. How do I print shelf talkers?

									<QuestionAnswer>
										Please follow the guide below for printing shelf talkers. You can choose between the 2x2 and 3x1 format.
										<div id='print-selected-reviews'></div>
										<div className='image'>
											<img src="/img/misc/FAQ-mobile-print-01.jpg" alt="Select wines then click on wines selected dropdown" />
										</div>
									</QuestionAnswer>

									<QuestionAnswer>
										<p style={{ margin: 2 }}><b>1. Selecting wines to print:</b></p>
										<p style={{ marginTop: 2 }}>After selecting multiple wines by checking the checkbox, click the dropdown “Wines Selected” for print options.</p>
										<div className='image'>
											<img src="/img/misc/FAQ-mobile-print-02.jpg" alt="Print options in wines selected dropdown" />
										</div>
									</QuestionAnswer>

									<QuestionAnswer>
										<p style={{ margin: 2 }}><b>2. Print options:</b></p>
										<p style={{ marginTop: 2 }}>Selecting the print options in the “Wines Selected” dropdown will print all the wines that you have selected. There are 4 print options available.</p>
										<p >Print note: 1 tasting note will be printed on 1 page</p>
										<p >Print 2 x 2 format: Each page will have 2 - 4 tasting notes laid out portrait style with separate borders 
										(shelf-talker)</p>
										<p >Print 3 x 1 format: Each page will have 3 tasting notes laid out portrait style with separate borders (shelf-talker)</p>
										<p >Print shopping list: All wines will be printed in a 1 page list without tasting notes. The list will include vintage, wine name and RP rating.</p>
										<p >If you want to narrow down only certain wines in your selection, click on View list, the first option.</p>
										<div className='image'>
											<img src="/img/misc/FAQ-mobile-print-03.jpg" alt="Make selections in View list pop-up" />
										</div>
									</QuestionAnswer>

									<QuestionAnswer>
										<p style={{ margin: 2 }}><b>3. View list</b></p>
										<p style={{ marginTop: 2 }}>A pop-up will appear when you click on View list. You can choose wines within the selection to print by checking the checkbox. To delete wines from the list, click “Remove from list”. To remove all wines from the list, click on “Remove All”.</p>
										<p >After selecting the wines you wish to print, click on the print options at the top. </p>
									</QuestionAnswer>
								</QuestionTitle>
								<QuestionTitle>
									Can I store my wine cellar information on the web site?
									<QuestionAnswer>
										Yes. Please click the link to RP Cellar for more information.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I am unable to access RP Cellar.
									<div id='subscriber-visibility'></div>

									<QuestionAnswer>
										You can access the current RP Cellar here: <TextStyle3><a href='https://cellar.robertparker.com'>https://cellar.robertparker.com</a></TextStyle3>. Log-in will be required. 
									You can log wines that exist in our wines database, even for vintages that have not been reviewed.
									</QuestionAnswer>

								</QuestionTitle>

								<QuestionTitle>
									Can I enter any wine I want in RP Cellar?
									<div id='chart-rating'></div>

									<QuestionAnswer>
										At this stage you may only enter wines which are already listed in the RobertParker.com database.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Will the information I enter be visible to other subscribers?
									<QuestionAnswer>
										Your tasting notes will be Only to other subscribers that are following you as a trusted taster.
									</QuestionAnswer>
								</QuestionTitle>

								<div id='maturity-classifcation'></div>

								<QuestionTitle>
									How are the wines in the vintage chart rated?
									<QuestionAnswer>
										Please head to this link for more explanation on the Rating System used:
										<p><TextStyle3><a href='/about/ratings'>https://www.robertparker.com/about/ratings</a></TextStyle3></p>
									</QuestionAnswer>
								</QuestionTitle>


								<QuestionTitle>
									What do the maturity classifications mean?
									<QuestionAnswer>
										<p>The vintage chart results are first displayed in a list that includes a column entitled &ldquo;Maturity&ldquo;. That column will contain one of the following values with the meanings shown:</p>
										<UL50PX>
											<li><TextStyle1>Young:</TextStyle1> the wine is not yet within the recommended drinking range suggested in the tasting note.</li>
											<li><TextStyle1>Early Maturity:</TextStyle1> the wine is in the first third of the recommended drinking range.</li>
											<li><TextStyle1>Mature:</TextStyle1> the wine is in the middle third of the recommended drinking range (or in the case of short ranges, anywhere within the range).</li>
											<li><TextStyle1>Late Maturity:</TextStyle1> the wine is in the final third of the recommended drinking range.</li>
											<li><TextStyle1>Past Maturity:</TextStyle1> the wine is past the recommended drinking range.</li>
											<li><TextStyle1>NA:</TextStyle1> no recommended drinking range was given in the note.</li>
										</UL50PX>
										<p>If a wine has been tasted more than once, the recommended drink dates are taken from the official tasting note (usually the most recent note, unless an earlier tasting appears to be more complete).</p>
									</QuestionAnswer>
								</QuestionTitle>

								<div id='next-issue-coverage'></div>
								<QuestionTitle>
									What wines will be covered in the next issue of The Wine Advocate?
									<QuestionAnswer>
										While it is subject to change, we post an editorial calendar here 
										<p>(<TextStyle3><a href='/editorial-calendar' >https://www.robertparker.com/editorial-calendar</a></TextStyle3>)</p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle id='incorrect-information'>
									There is incorrect and/or missing information in the website.
									<QuestionAnswer>
										Thank you for highlighting it to us. Kindly email the erroneous information to <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3> and we will have our Editorial team look into them.
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainerMobile>
						</Row>

					</QAContainer>

				</>
				:
				<>
					<PageTitle>Site Content {'&'} Features</PageTitle>
					<QAContainer>
						<Row>
							<QuestionContainer>
								<Question>
									<HashLink to='#rp-information'>What information is available on RobertParker.com?</HashLink>
								</Question>
								<Question>
									<HashLink to='#print-information'>Can I print the information?</HashLink>
								</Question>
								<Question>
									<HashLink to='#print-shelf-takers'>I am a Commercial Subscriber. How do I print shelf talkers?</HashLink>
								</Question>
								<Question>
									<HashLink to='#store-wine-cellar-info'>Can I store my wine cellar information on the web site?</HashLink>
								</Question>
								<Question>
									<HashLink to='#enter-wine'>Can I enter any wine I want in RP Cellar?</HashLink>
								</Question>
								<Question>
									<HashLink to='#subscriber-visibility'>Will the information I enter be visible to other subscribers?</HashLink>
								</Question>
								<Question>
									<HashLink to='#chart-rating'>How are the wines in the vintage chart rated?</HashLink>
								</Question>
								<Question>
									<HashLink to='#maturity-classifcation'>What do the maturity classifications mean?</HashLink>
								</Question>
								<Question>
									<HashLink to='#next-issue-coverage'>What wines will be covered in the next issue of The Wine Advocate?</HashLink>
								</Question>
								<Question>
									<HashLink to='#incorrect-information'>There is incorrect and/or missing information in the website.</HashLink>
								</Question>
							</QuestionContainer>
							<AnswerContainer>
								<div id='#rp-information'></div>
								<QuestionTitle>
									What information is available on RobertParker.com?
									<QuestionAnswer>
										<CustomUL>
											<li>
												Virtually all tasting notes from <i>The Wine Advocate</i> dating back to 1992, plus hundreds of “overflow” tastings that the printed edition could not accommodate. There are tasting notes for over 450,000 wines on the site today, and we publish weekly.
											</li>
											<li>
												Most of the articles from <i>The Wine Advocate</i>, starting from the 1995 issues plus articles from Robert Parker’s books and other sources.
											</li>
											<li>
												<TextStyle3><Link to="/free-publications/wine-journal">Wine Journal:</Link></TextStyle3> <i>The Wine Advocate</i> reviewers publish stories detailing their outlandish gustatory experiences along with informal tasting notes not published elsewhere.
											</li>
											<li>
												Wine-Searcher prices: Through our partnership with Wine-Searcher, we offer single-click access to current retail offerings already indexed to our tens of thousands of wines to assist you in finally landing some of those hard-to-find wines you’ve always wanted.
											</li>
										</CustomUL>

									</QuestionAnswer>
								</QuestionTitle>
								<div id='print-shelf-takers'></div>
								<QuestionTitle>
									Can I print the information?
									<QuestionAnswer>
										Personal subscribers can print single tasting notes. Commercial subscribers have the option to print shelf talkers in different formats that are most useful to present a wine with its score and tasting note.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I am a Commercial Subscriber. How do I print shelf talkers?

									<QuestionAnswer>
										Please follow the guide below for printing shelf talkers. You can choose between the 2x2 and 3x1 format.
										<div id='print-selected-reviews'></div>
										<div className='image'>
											<img src="/img/misc/FAQ-desktop-print-01.jpg" alt="Select wines then click on wines selected dropdown" />
										</div>
									</QuestionAnswer>

									<QuestionAnswer>
										<p style={{ margin: 2 }}><b>1. Selecting wines to print:</b></p>
										<p style={{ marginTop: 2 }}>After selecting multiple wines by checking the checkbox, click the dropdown “Wines Selected” for print options.</p>
										<div className='image'>
											<img src="/img/misc/FAQ-desktop-print-02.jpg" alt="Print options in wines selected dropdown" />
										</div>
									</QuestionAnswer>

									<QuestionAnswer>
										<p style={{ margin: 2 }}><b>2. Print options:</b></p>
										<p style={{ marginTop: 2 }}>Selecting the print options in the “Wines Selected” dropdown will print all the wines that you have selected. There are 4 print options available.</p>
										<p >Print note: 1 tasting note will be printed on 1 page</p>
										<p >Print 2 x 2 format: Each page will have 2 - 4 tasting notes laid out portrait style with separate borders 
										(shelf-talker)</p>
										<p >Print 3 x 1 format: Each page will have 3 tasting notes laid out portrait style with separate borders (shelf-talker)</p>
										<p >Print shopping list: All wines will be printed in a 1 page list without tasting notes. The list will include vintage, wine name and RP rating.</p>
										<p >If you want to narrow down only certain wines in your selection, click on View list, the first option.</p>
										<div className='image'>
											<img src="/img/misc/FAQ-desktop-print-03.jpg" alt="Make selections in View list pop-up" />
										</div>
									</QuestionAnswer>

									<QuestionAnswer>
										<p style={{ margin: 2 }}><b>3. View list</b></p>
										<p style={{ marginTop: 2 }}>A pop-up will appear when you click on View list. You can choose wines within the selection to print by checking the checkbox. To delete wines from the list, click “Remove from list”. To remove all wines from the list, click on “Remove All”.</p>
										<p >After selecting the wines you wish to print, click on the print options at the top. </p>
									</QuestionAnswer>
								</QuestionTitle>
								<QuestionTitle>
									Can I store my wine cellar information on the web site?
									<QuestionAnswer>
										Yes. Please click the link to RP Cellar for more information.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I am unable to access RP Cellar.
									<div id='subscriber-visibility'></div>

									<QuestionAnswer>
										You can access the current RP Cellar here: <TextStyle3><a href='https://cellar.robertparker.com'>https://cellar.robertparker.com</a></TextStyle3>. Log-in will be required. 
										You can log wines that exist in our wines database, even for vintages that have not been reviewed.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Can I enter any wine I want in RP Cellar?
									<div id='chart-rating'></div>

									<QuestionAnswer>
										At this stage you may only enter wines which are already listed in the RobertParker.com database.
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Will the information I enter be visible to other subscribers?
									<QuestionAnswer>
										Your tasting notes will be Only to other subscribers that are following you as a trusted taster.
									</QuestionAnswer>
								</QuestionTitle>

								<div id='maturity-classifcation'></div>

								<QuestionTitle>
									How are the wines in the vintage chart rated?
									<QuestionAnswer>
										Please head to this link for more information on the Rating System used:
										<p><TextStyle3><a href='/about/ratings'>https://www.robertparker.com/about/ratings</a></TextStyle3></p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									What do the maturity classifications mean?
									<QuestionAnswer>
										<p>The vintage chart results are first displayed in a list that includes a column entitled &ldquo;Maturity&ldquo;. That column will contain one of the following values with the meanings shown:</p>
										<UL50PX>
											<li><TextStyle1>Young:</TextStyle1> the wine is not yet within the recommended drinking range suggested in the tasting note.</li>
											<li><TextStyle1>Early Maturity:</TextStyle1> the wine is in the first third of the recommended drinking range.</li>
											<li><TextStyle1>Mature:</TextStyle1> the wine is in the middle third of the recommended drinking range (or in the case of short ranges, anywhere within the range).</li>
											<li><TextStyle1>Late Maturity:</TextStyle1> the wine is in the final third of the recommended drinking range.</li>
											<li><TextStyle1>Past Maturity:</TextStyle1> the wine is past the recommended drinking range.</li>
											<li><TextStyle1>NA:</TextStyle1> no recommended drinking range was given in the note.</li>
										</UL50PX>
										<p>If a wine has been tasted more than once, the recommended drink dates are taken from the official tasting note (usually the most recent note, unless an earlier tasting appears to be more complete).</p>
									</QuestionAnswer>
								</QuestionTitle>

								<div id='next-issue-coverage'></div>
								<QuestionTitle>
									What wines will be covered in the next issue of The Wine Advocate?
									<QuestionAnswer>
										While it is subject to change, we post an editorial calendar here 
										<p>(<TextStyle3><a href='/editorial-calendar' >https://www.robertparker.com/editorial-calendar</a></TextStyle3>)</p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle id='incorrect-information'>
									There is incorrect and/or missing information in the website.
									<QuestionAnswer>
										Kindly email the erroneous information to <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></TextStyle3> and we will have our Editorial team look into it.
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainer>
						</Row>

					</QAContainer>
				</>
			}
		</div>
	)
}

export default SiteContentAndFeatures