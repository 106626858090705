/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { ClearUserSession, IsUserAuthenticated, TranslationsUtil } from '../../../utils'
import englishTranslation from './translations/en.json'
import { useAppSelector } from '../../../app/hooks'
// import SocialMediaLinks from '../../../components/social-media/SocialMediaLinks'
import { EXTERNAL_LIKS } from '../../../configs'

import {
	SidebarAccount,
	SidebarAccountIcon,
	SidebarAccountName,
	SidebarAccountSub,
	SidebarLoginButton,
	SidebarLoginIcon,
	SidebarLoginName,
	SidebarLoginSigUpAccount,
	SidebarMenu,
	SidebarMenuSub,
	SidebarSubscribebutton,
	SidebarSubscribeIcon,
	SidebarSubscribeName,
	UserNameContainer,
	UserNameMenu,
} from './styles'
import './styles.css'
import { Users as UsersFromStore } from '../../../app/slices/Users'
import TagManager from 'react-gtm-module'
import { useBasePath } from '../../content/Common'
import { validateURL } from '../../../utils/ValidateUrl'
import { CSSTransition } from 'react-transition-group'
import CommonModalContainer from '../ModalContainer'
import LoginForms from '../../content/Common/Login/LoginForms'
import {Authentication as AuthFromStore} from '../../../app/slices/Authentication'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

const OffCanvasMenu: React.FC = () => {
	const translate = translationUtil.getTranslator()

	const { userData } = useAppSelector(UsersFromStore)
	const pathname = useBasePath()
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
	

	const subscriptionType = userData?.subscription?.type

	const dispatch = useDispatch()

	const links = document.getElementsByClassName('your-link')

	for (let i = 0; i < links.length; i++) {
		const link = links[i] as HTMLAnchorElement
		link.addEventListener('touchstart', function () {
			window.location.href = link.href
		})
	}
	const [fullName, setFullName] = useState('')
	const authentication = useAppSelector(AuthFromStore)

	useEffect(() => {
		if(userData){
			if (userData) {
				if (authentication.User.accessToken) {
					const objectData = JSON.parse(JSON.stringify(userData).replace(/</g, '\\u003c'))
					setFullName(`${objectData?.profile?.name?.first_name} ${objectData?.profile?.name?.last_name}`)
				}
			}
		}
	},[userData])

	const execLogout = () => {
		ClearUserSession(dispatch)
		window.location.href = '/'
	}

	const triggerSubscription = () => {
		TagManager.dataLayer({
			dataLayer: {
				'event': 'subscribe_now_btn',
				'page_origin': pathname
			}
		})
	}
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [menuOpen, setMenuOpen] = useState('')
	const menuValue = useRef('') 
	const handleClick = (value: string) => {
		if(menuValue.current === value) {
			setMenuOpen('')
			menuValue.current = ''
		} else{
			setMenuOpen(value)
			menuValue.current = value
		}
		setIsMenuOpen(!isMenuOpen)

	}
	
	const getLoginButton = () => (
		<>
			<SidebarSubscribebutton>
				<a href='/subscriptions' onClick={triggerSubscription} className="btn btn-subscribe-sidebar your-link"><SidebarSubscribeIcon><img src={'/img/icons/elements-icons-mid-book.png'} /></SidebarSubscribeIcon>
					<SidebarSubscribeName><span>{translate('SUBSCRIBE NOW')}</span></SidebarSubscribeName>
				</a>
			</SidebarSubscribebutton>
			<SidebarLoginButton>
				<div className="btn btn-default btn-login-sidebar" onClick={()=> setIsLoginModalOpen(true)}><SidebarLoginIcon><i className="mdicon mdicon-person mdicon--first"></i></SidebarLoginIcon>
					<SidebarLoginName><span>{translate('LOGIN / SIGN UP')}</span></SidebarLoginName>
				</div>
			</SidebarLoginButton>
		</>
	)

	const getUserMenu = () => {
		return (
			<UserNameMenu>
				<UserNameContainer>
					<div className='menu-title username-title' onClick={() => handleClick('profile')}><SidebarAccountIcon><i className="mdicon mdicon-person"></i></SidebarAccountIcon> 
						<SidebarAccountName>
							{userData &&
						<span>
							{IsUserAuthenticated() && userData ? fullName : ''}
						</span>	
							}
						
						</SidebarAccountName>
						<div>
							{menuOpen === 'profile' ?
								<img src='img/icons/expand-less-2.png' style={{ height: '6px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
								:
								<img src='img/icons/expand-more-2.png' style={{ height: '10px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
							}
						</div>
					</div>
				</UserNameContainer>
				<CSSTransition
					in={menuOpen === 'profile'}
					timeout={300}
					classNames="sub-menu"
					unmountOnExit
				>
					<ul className='sub-menu'>
						<li>
							<a href='/my-profile/edit-profile'><SidebarAccountSub>My Profile</SidebarAccountSub></a>
						</li>
						<li>
							<Link to='/my-subscription/subscription-details' ><SidebarAccountSub>Manage Subscription</SidebarAccountSub></Link>
						</li>
						{subscriptionType === 'commercial' && <li>
							<a href='/my-company/manage-group'><SidebarAccountSub>Manage Company</SidebarAccountSub></a>
						</li>}
						<li>
							<a onClick={execLogout}><SidebarAccountSub>Logout</SidebarAccountSub></a>
						</li>
					</ul>
				</CSSTransition>
			</UserNameMenu>
		)
	}
	
	return (
		<>
			<div id="mnmd-offcanvas-primary" className="mnmd-offcanvas js-mnmd-offcanvas js-perfect-scrollbar">
				<div className="mnmd-offcanvas__title">
					<h2 className="site-logo">
						<a className='your-link' href="#">
							<img src="img/logo-mark-color.png" alt="logo" width="240" />
						</a>
					</h2>

					<a href="#mnmd-offcanvas-primary" className="mnmd-offcanvas-close js-mnmd-offcanvas-close" aria-label="Close">
						<span aria-hidden="true">&#10005;</span>
					</a>
				</div>
				
				{!IsUserAuthenticated() ? 
					<SidebarLoginSigUpAccount>
						{getLoginButton()}
					</SidebarLoginSigUpAccount>
					: 
					<SidebarAccount>
						{getUserMenu()}
					</SidebarAccount>
				}
				<div className="mnmd-offcanvas__section mnmd-offcanvas__section-navigation">
					<a className='your-link menu-title' href="#"><SidebarMenu>Home</SidebarMenu></a>
					<UserNameMenu>
						<div className='menu-title' onClick={() => handleClick('freetoread')}><SidebarMenu>The Wine Advocate</SidebarMenu><div>
							{menuOpen === 'freetoread' ?
								<img src='img/icons/expand-less-2.png' style={{ height: '6px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
								:
								<img src='img/icons/expand-more-2.png' style={{ height: '10px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
							}
						</div>
						</div>
						<CSSTransition
							in={menuOpen === 'freetoread'}
							timeout={300}
							classNames="sub-menu"
							unmountOnExit
						>
							<ul className="sub-menu">
								<li>
									<a className='your-link' href='/issues/latest'><SidebarMenuSub>The Wine Advocate</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/more-free-stuff'><SidebarMenuSub>Free to Read</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/free-publications/wine-journal'><SidebarMenuSub>Wine Journal</SidebarMenuSub></a>
								</li>
							</ul>
						</CSSTransition>
					</UserNameMenu>
					<UserNameMenu>
						<div className='menu-title' onClick={() => handleClick('aboutus')}><SidebarMenu>About Us</SidebarMenu><div>
							{menuOpen === 'aboutus' ?
								<img src='img/icons/expand-less-2.png' style={{ height: '6px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
								:
								<img src='img/icons/expand-more-2.png' style={{ height: '10px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
							}
						</div>
						</div>
						<CSSTransition
							in={menuOpen === 'aboutus'}
							timeout={300}
							classNames="sub-menu"
							unmountOnExit
						>
							<ul className="sub-menu">
								<li>
									<a className='your-link' href='/about/reviewers'><SidebarMenuSub>Our Reviewer Team</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/about/the-wine-advocate'><SidebarMenuSub>About The Wine Advocate</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/about/origins-of-the-wine-advocate'><SidebarMenuSub>The Origins of the Wine Advocate</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/about/reports'><SidebarMenuSub>The Wine Advocate Reports</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/about/the-rating-system'><SidebarMenuSub>RPWA Rating System</SidebarMenuSub></a>
								</li>
							</ul>
						</CSSTransition>
					</UserNameMenu>
					<a className='your-link' href={validateURL(`${EXTERNAL_LIKS.EVENTS_PAGE}`) ? `${EXTERNAL_LIKS.EVENTS_PAGE}` : ''} target="_blank" rel="noreferrer"><SidebarMenu>Events</SidebarMenu></a>
					<a className='your-link' href='/vintage-chart'><SidebarMenu>Vintage Chart</SidebarMenu></a>
					<UserNameMenu>
						<div className='menu-title' onClick={() => handleClick('greenemblem')}><SidebarMenu>Green Emblem</SidebarMenu><div>
							{menuOpen === 'greenemblem' ?
								<img src='img/icons/expand-less-2.png' style={{ height: '6px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
								:
								<img src='img/icons/expand-more-2.png' style={{ height: '10px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
							}
						</div>
						</div>
						<CSSTransition
							in={menuOpen === 'greenemblem'}
							timeout={300}
							classNames="sub-menu"
							unmountOnExit
						>
							<ul className="sub-menu">
								<li>
									<a className='your-link' href='/sustainability-and-wine/green-emblem-list'><SidebarMenuSub>Green Emblem Wineries</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/free-publications/green-emblem'><SidebarMenuSub>Green Emblem Mission & Objectives</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/free-publications/certification'><SidebarMenuSub>Certified Sustainability</SidebarMenuSub></a>
								</li>
							</ul>
						</CSSTransition>
					</UserNameMenu>
					<UserNameMenu>
						<div className='menu-title' onClick={() => handleClick('editorialcalendar')}><SidebarMenu>Resources</SidebarMenu><div>
							{menuOpen === 'editorialcalendar' ?
								<img src='img/icons/expand-less-2.png' style={{ height: '6px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
								:
								<img src='img/icons/expand-more-2.png' style={{ height: '10px', marginTop: '5px', marginRight: '15px', float: 'right' }} />
							}
						</div>
						</div>
						<CSSTransition
							in={menuOpen === 'editorialcalendar'}
							timeout={300}
							classNames="sub-menu"
							unmountOnExit
						>
							<ul className="sub-menu">
								<li>
									<a className='your-link' href={validateURL(`${EXTERNAL_LIKS.RP_CELLAR}`) ? `${EXTERNAL_LIKS.RP_CELLAR}` : ''} target="_blank" rel="noreferrer"><SidebarMenuSub>RP Cellar</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/editorial-calendar'><SidebarMenuSub>Editorial Calendar</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/trade-partners/trade-directory'><SidebarMenuSub>Trade Directory</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/marketing-materials' ><SidebarMenuSub>Marketing Materials</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/faq'><SidebarMenuSub>FAQ</SidebarMenuSub></a>
								</li>
								<li>
									<a className='your-link' href='/resources/about-wines'  ><SidebarMenuSub>Glossary of Terms</SidebarMenuSub></a>
								</li>
							</ul>
						</CSSTransition>
					</UserNameMenu>	
				</div>
				{/* <div className="mnmd-offcanvas__section visible-xs visible-sm">
					<SocialMediaLinks containerClasses="social-list list-horizontal list-space-xs" />
				</div> */}
			</div>
			<CommonModalContainer isOpen={isLoginModalOpen} onClose={()=> setIsLoginModalOpen(false)}>
				<LoginForms setIsOpen={setIsLoginModalOpen} />
			</CommonModalContainer>
		</>
	)
}

export default OffCanvasMenu