/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react'
import Carousel from 'react-simply-carousel'
import { isMobile } from 'react-device-detect'

import LoadingOverlay from '../../components/Loading-overlay'

// Styles
import { imageConvertion } from '../../utils/CloudImageUtils'
import { useGetListHomeBannersQuery } from '../../app/services'
import { TitleContainerMobile, TitleContainerWeb } from './styles'

function CarouselBanners(): ReactElement {
	const [activeSlide, setActiveSlide] = useState(0)

	const {data, isLoading} = useGetListHomeBannersQuery()
	// const { triggerTrackingHandler } = useArticleTracking()

	/**
	 * redirects user to either the article or the issue
	 * @param banner
	 */
	const redirectUser = (banner: any) => {
		let bannerLink = banner.button?.url

		if(bannerLink.includes('www.robertparker.com') || bannerLink.includes('v1.robertparker.com')){
			const url = new URL(bannerLink)
			bannerLink = url.pathname		
		}

		window.location.href = bannerLink
	}
	

	return (
		<div className="mnmd-block mnmd-block--fullwidth featured-with-overlap-posts mnmd-block--contiguous">
			{isLoading ? (<LoadingOverlay />) : (<div>
				{data && 
					<Carousel
						containerProps={{
							style: {
								justifyContent: 'space-between',
								userSelect: 'text',
							}
						}}
						activeSlideIndex={activeSlide}
						activeSlideProps={{
							style: {
							}
						}}
						onRequestChange={setActiveSlide}
						forwardBtnProps={{
							children: '>',
							style: {
								right: '20px',
								alignSelf: 'center',
								zIndex: 100,
								position: 'absolute',
								fontSize: '40px',
								color: 'white',
								background: 'none',
								borderStyle: 'none',
							}
						}}
						backwardBtnProps={{
							children: '<',
							style: {
								left: '20px',
								alignSelf: 'center',
								zIndex: 100,
								position: 'absolute',
								fontSize: '40px',
								color: 'white',
								background: 'none',
								borderStyle: 'none',
							}
						}}
						dotsNav={{
							show: false,
							itemBtnProps: {
								style: {
									height: 16,
									width: 16,
									borderRadius: '50%',
									border: 0
								}
							},
							activeItemBtnProps: {
								style: {
									height: 16,
									width: 16,
									borderRadius: '50%',
									border: 0,
									background: 'black'
								}
							}
						}}
						itemsToShow={1}
						speed={500}
						autoplay={true}
						autoplayDelay={5000}
					>
						{data.map((banner: any, index: number) => (
							banner.key === 'homepage' &&
							<div
								style={{
									position: 'relative',
									backgroundImage: `url(${imageConvertion(banner.media.url)})`,
									width: isMobile ? '89vw' : 1120,
									height: 200,
									border: '1px solid black',
									textAlign: 'center',
									lineHeight: '200px',
									boxSizing: 'border-box',
									cursor: 'pointer',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat',
								}}
								key={index}
								onClick={() => { redirectUser(banner) }}
							>
								<div
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										backgroundColor: 'rgba(0, 0, 0, 0.2)', 
									}}
								/>
								{isMobile ? <TitleContainerMobile>{banner.title}</TitleContainerMobile> : <TitleContainerWeb><div>{banner.title}</div></TitleContainerWeb>}
							</div>
						))}
					</Carousel>}
			</div>)}
		</div>

	)
}

export default CarouselBanners