/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { GetBaseQuery } from '../../utils/'

import { sprintf } from 'sprintf-js'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

export const ArticlesAPIv2 = createApi({
	reducerPath: 'ArticlesAPIv2',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getWineJournalArticles: builder.query<any, {offset: number, limit: number, authorId: string, categoryId: string}>({
			query: ({offset, limit, authorId, categoryId}) => `${API_ENDPOINTS.get_wine_journal_articles}?offset=${offset}&limit=${limit}&authorId=${authorId}&categoryId=${categoryId}`,
		}),
		getListofAuthors: builder.query<any, void>({
			query: () => API_ENDPOINTS.get_list_authors,
		}),
		getFeaturedWineJournalArticles: builder.query<any, {offset: number, limit: number}>({
			query: ({offset, limit}) => `${API_ENDPOINTS.get_featured_wine_journal_articles}?offset=${offset}&limit=${limit}`,
		}),
		getRecommendedReads: builder.query<Articlev2, void>({
			query: () => API_ENDPOINTS.get_recommended_reads_v2,
		}),
		getMoreFreeStuff: builder.query<any, void>({
			query: () => API_ENDPOINTS.get_more_free_stuffv2,
		}),
		getFeaturedWineAdvocate: builder.query<any, void>({
			query: () => API_ENDPOINTS.get_featured_wine_advocate
		}),	
		getPublishedWineArticles: builder.query<any,string>({
			query: (wineId:string) => sprintf(API_ENDPOINTS.get_published_articles, wineId),
		}),
		getCurrentNextAndPreviousIssue:builder.query<any,string>({
			query: (issueId:string) => sprintf(API_ENDPOINTS.get_issue_details, issueId),
		}),
		getArticleHighlights:builder.query<any,{offset: number, limit: number}>({
			query: ({offset, limit}) =>  `${API_ENDPOINTS.get_article_highlights}?offset=${offset}&limit=${limit}`,
		}),
		getArticleById:builder.query<any,string>({
			query: (articleId) =>  sprintf(API_ENDPOINTS.get_article_by_id, articleId)
		}),
		getArticleByAuthor:builder.query<any,{authorId: string, offset?:number, limit?:number}>({
			query: ({authorId, offset=0, limit=3}) =>  `${API_ENDPOINTS.get_article_by_author}?authorId=${authorId}&offset=${offset}&limit=${limit}`
		}),
	}),
})


export const {
	useGetRecommendedReadsQuery: useGetRecommendedReadsV2Query,
	useGetMoreFreeStuffQuery: useGetmoreFreeStuffV2Query,
	useGetFeaturedWineAdvocateQuery,
	useGetPublishedWineArticlesQuery,
	useGetCurrentNextAndPreviousIssueQuery,
	useGetArticleHighlightsQuery,
	useGetArticleByIdQuery,
	useGetArticleByAuthorQuery,
	useGetWineJournalArticlesQuery,
	useGetFeaturedWineJournalArticlesQuery,
	useGetListofAuthorsQuery
} = ArticlesAPIv2


