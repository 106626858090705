/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form'

import { DatePickerContainer, ErrorMessage, StyledDatePickerWrapper } from '../styles'
import Icons from '../../../views/content/pages/redemption-codes/redeem/icons'
import moment from 'moment'

type Props = {
	control: Control<FieldValues>;
	rules?: RegisterOptions;
	name: string;
	placeholder?: string;
	[x: string]: any;
}

function DatePickerComponent({ control, name, placeholder, rules, ...rest }: Props): ReactElement {
	const datePickerRef = useRef<DatePicker>(null)

	const openCalendar = (): void => {
		if (datePickerRef.current) {
			datePickerRef.current.setOpen(true)
		}
	}

	return <Controller
		control={control}
		rules={rules}
		name={name}
		render={({ field: { onChange, value = new Date() }, fieldState: { error } }) => {
			return (<DatePickerContainer className='DatePickerContainer'>
				<StyledDatePickerWrapper isError={error ? true : false} className='StyledDatePickerWrapper'>
					<div className='from-field'>
						<DatePicker className='component-design'
							onChange={onChange}
							showMonthDropdown
							showYearDropdown
							showTimeInput
							selected={value}
							dropdownMode="select"
							dateFormat="MM/dd/yyyy h:mm aa"
							minDate={moment().toDate()}
							placeholderText="Select a date and time"
							calendarClassName="my-calendar"
							ref={datePickerRef}
							{...rest}
						/>
					</div>
					<Icons.CalendarIcon className='calendar-icon' height='20px' width='20px' onClick={openCalendar} />
				</StyledDatePickerWrapper>
				{error ? <ErrorMessage>{error?.message as string}</ErrorMessage> : <p style={{ paddingTop: name === 'redemptionCode' ? 21 : 7 }}></p>}
			</DatePickerContainer>
			)
		}}
	/>
}

export default DatePickerComponent
