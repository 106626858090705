/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as _ from 'lodash'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'

import SectionSeparator from '../../sections/section-separator'

// Styles
import { FilterContainer, ListContainer, ListItem, BlueButton, BlueButtonMobile, PageTitle, SubTitle } from './styles'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { SetActiveAdvanceFilters, SetActiveFilters, SetFacetsFilters } from '../../../../app/slices/SearchFilters'
import { filtersFromQueryString } from '../../../../utils/FilterUtils'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { useGetListEcoQuery } from '../../../../app/services'

function GreenEmblemList(): ReactElement {

	const dispatch = useDispatch()

	const history = useHistory()

	const {data, isLoading} = useGetListEcoQuery()
	
	const [companies, setCompanies] = React.useState<any>()
	const [companyCount, setCompanyCount] = useState(0)
	const [countries, setCountries] = React.useState<any>()
	const [currentCountry, setCurrentCountry] = useState('all')

	const arraySplitter = (accumulator: any, currentValue: any, currentIndex: any, array: any) => {
		if (currentIndex % 2 === 0)
			accumulator.push(array.slice(currentIndex, currentIndex + 2))
		return accumulator
	}

	useEffect(() => {
		if (data && data.companies) {
			// Groups the list into 2 elements
			setCompanyCount(data.companies.length)
			setCompanies(data.companies.reduce(arraySplitter, []))


			const uniqueCountries = _.uniq(data.companies.map((company: any) => company.country.name))
			setCountries(uniqueCountries.sort())
		}
	}, [data])

	useEffect(() => {
		if (data && data.companies) {

			let companyList

			if (currentCountry !== 'all') {
				companyList = data.companies.filter((company: any) => company.country.name === currentCountry)
			} else {
				companyList = data.companies
			}

			setCompanyCount(companyList.length)
			setCompanies(companyList.reduce(arraySplitter, []))

		}

	}, [currentCountry, data])

	const winesByProducerHandler = (qString: string) => {
		const queryStrings = queryString.parse(qString) as any
		dispatch(SetFacetsFilters({
			activeFilters: filtersFromQueryString(queryStrings),
		}))
	}

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<div>
						<div><PageTitle>Discover Robert Parker Green Emblem Wineries</PageTitle></div>
						<div><SubTitle>Enjoy wines that are made for a better future</SubTitle></div>
						<div>
							{isMobile ? <BlueButtonMobile onClick={() => history.push('/free-publications/green-emblem')}>READ THE GREEN EMBLEM MISSION & OBJECTIVES</BlueButtonMobile> : <BlueButton onClick={() => history.push('/free-publications/green-emblem')}>READ THE GREEN EMBLEM MISSION & OBJECTIVES</BlueButton>}
						</div>
					</div>
					<SectionSeparator position={'horizontal'}></SectionSeparator>
				</div>
			</div>
			<div className="container">
				{isLoading ? (<LoadingOverlay />) : (companies && companies.length > 0) ? (
					<div>
						<FilterContainer>
							<div className={'filter'} >
								<label>Filter</label>
								<select name="country_filter" className='component-design' value={currentCountry} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { setCurrentCountry(event.target.value) }} >
									<option value='all'>All</option>
									{countries.map((countryOption: string, country_key: number) => (<option key={country_key} value={countryOption} >{countryOption}</option>))}
								</select>
							</div>
							<div className={'counter'} >{companyCount} Entries</div>
						</FilterContainer>
						<ListContainer>
							{companies.map((row: any, company_key: number) =>  {
								const rowLength = row.length
								return <div className={'list-row'} key={company_key} >
									{row.map((company: GreenEmblemCompany, companyIndex: number) => (
										<ListItem className={`${rowLength < 2 ? 'single-emblem' : ''}`} placement={companyIndex === 0 ? 'left' : 'right'} key={companyIndex + '_' + company._id}>
											<div className={'photo'} >
												{company.banner && company.banner?.url ? (<>
													{/* <img src={company.banner?.url} width={'565px'} height={'190px'} /> */}
													<CloudImageUtils imgSrc={company.banner?.url} alt="image" ratio={0} params=''/>
												</>) : (<></>)}
											</div>
											<div className={'country'} >{company.country && company.country.name ? company.country.name.toUpperCase() : ''}</div>
											<div className={'name'}>
												<Link to={`/green-emblem/${company._id}`} >{company.name}</Link>
											</div>
											<div className={'description'} >{company.comment} </div>
											<div><Link onClick={() => {
												winesByProducerHandler(`?&producer=${encodeURIComponent(company.name)}`)
												dispatch(SetActiveFilters({}))
												dispatch(SetActiveAdvanceFilters({}))
											}} to={`/search/wine?&producer=${encodeURIComponent(company.name)}`}>See all wines by producer</Link></div>
										</ListItem>
									))}
								</div>
							})}
						</ListContainer>
					</div>
				) : (<></>)}

			</div>
		</div>
	)
}

export default GreenEmblemList