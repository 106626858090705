import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import NonExistPage from '../../404-page/NonExistPage'
import AddGroup from './AddGroup'
import CompanyUpdate from '../CompanyUpdate'
import MyCompany from '..'

function CompanyManagement(): ReactElement {
	const { type } = useParams<{ type: string }>()

	if (type === 'add-group') {
		return <AddGroup />
	} else if (type === 'update-company-information' || type === 'update-contact-information') {
		return <CompanyUpdate />
	} else if (type === 'manage-group' || type === 'company-details'){
		return <MyCompany />
	} else {
		return <NonExistPage />
	}
}

export default CompanyManagement
